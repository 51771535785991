import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { logout } from '../../../actions/authActions';
// mocks_
import account from '../../../_mock/account';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Accueil',
        icon: 'eva:home-fill',
    }
];

// ----------------------------------------------------------------------

export default function HelpPopover() {
    const [open, setOpen] = useState(null);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openNewTab = () => {
        window.open(
            'https://netbee-be.atlassian.net/wiki/external/NjhjNjZkNTQwMDYyNGRjMjkzOTA0MzE1NDg5YmExODA',
            '_blank'
        );
    };

    return (
        <>
            <IconButton
                onClick={openNewTab}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <HelpIcon color="primary" fontSize="medium" />
            </IconButton>
        </>
    );
}