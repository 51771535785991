import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';


export const postCollect = async (CustomerCollectDTO) => {
    const response = await request('POST', `CustomerCollectRequest`, CustomerCollectDTO);
    return response.data;
};

export const deleteCollect = async (id) => {
    const response = await request('DELETE', `CustomerCollectRequest/${id}`);
    return response.data;
};

export const putCollect = async (CustomerCollectDTO) => {
    const response = await request('PUT', `CustomerCollectRequest`, CustomerCollectDTO);
    return response.data;
};

export const getCollect = async (id) => {
    const response = await request('GET', `CustomerCollectRequest/id?id=${id}`);
    return response.data;
};

export const getCustomerInformation = async (guid) => {
    const response = await request('GET', `GuaranteeItem/code/${guid}`);
    return response.data;
}; 