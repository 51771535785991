import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import toast, { Toaster } from 'react-hot-toast';

import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Alert,
    Button,
    Container,
    Typography,
    Grid
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { changePasswordAPI, resetPassword } from '../../../services/authService';
import { changePassword } from '../../../actions/authActions';
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';

export default function ForgetPassword() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const StyledRoot = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    }));

    const StyledContent = styled('div')(({ theme }) => ({
        maxWidth: 480,
        margin: 'auto',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(12, 0),
    }));

    const handleClick = async () => {
        toast.promise(
            resetPassword(email),
            {
                loading: t('forget-password-reset-password'),
                success: <b>{t('forget-password-send-mail')}</b>,
                error: <b>{t('forget-password-error')}</b>,
            }
        );
    };

    return (

        <Container maxWidth="sm">

            <Logo sx={{ mx: 'auto', my: 10 }} />


            <Stack spacing={3}>

                <Grid>
                    <Typography variant="h4" gutterBottom>
                        {t('forget-password-reset-password')}
                    </Typography>
                </Grid>

                <TextField
                    name="email"
                    label={t('login-mail-address')}
                    value={email}
                    onChange={onChangeEmail}
                />

                <LoadingButton
                    fullWidth
                    size="large"
                    disabled={(!email)}
                    type="submit"
                    variant="contained"
                    onClick={handleClick}>
                    {t('forget-password-reset-action')}
                </LoadingButton>

                <Button onClick={() => { navigate('/') }}>
                    <ArrowBackIcon />
                    {t('set-password-back')}
                </Button>
            </Stack>


        </Container>




    );
}
