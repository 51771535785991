import axios from 'axios';
import { API_URL } from './api-uri';
import { refreshToken, getAccessToken } from './tokenService';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (config) => {
    const token = await getAccessToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export const request = async (method, uri, params) => {
    try {
        switch (method) {
            case 'POST':
                return await api.post(uri, params);
            case 'PUT':
                return await api.put(uri, params);
            case 'DELETE':
                return await api.delete(uri, { params });
            case 'GET':
                return await api.get(uri, { params });
            default:
                throw new Error('Méthode HTTP non supportée');
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            await refreshToken();
            const newToken = await getAccessToken();
            if (newToken) {
                const updatedApi = axios.create({
                    baseURL: API_URL,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${newToken}`,
                    },
                });
                const response = await updatedApi[method.toLowerCase()](uri, params);
                return response.data;
            }
        }
        throw error;
    }
};
