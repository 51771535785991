import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import toast from 'react-hot-toast';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Switch,
  Grid
} from '@mui/material';
import Label from '../../../components/label';
import { DeleteWorker } from '../../../services/workerService';
import Iconify from '../../../components/iconify';
import YesNo from '../../../pages/YesNo';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function ResidentListToolbar({
  numSelected,
  filterName,
  onFilterName,
  filterActive,
  onFilterActive,
  filterAlive,
  onFilterAlive,
  selected,
  userList,
  setUserList,
  setSelected,
}) {

  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleConfirmDelete = async () => {

    selected.forEach(async (id) => {
      const resident = userList.find((user) => user.id === id);
      if (await DeleteWorker(id)) {
        const updatedUserList = userList.filter((user) => user.id !== id);
        setUserList(updatedUserList);
        toast.success(`Le résident ${resident.firstname} ${resident.lastname} a été supprimé avec succès`);
      } else {
        toast.error(`Une erreur est survenue lors de la suppression du résident ${resident.firstname} ${resident.lastname}`)
      }
    });

    setSelected([]);
    setOpen(false);
  };

  const handleCancelDelete = () => {
    setSelected([]);
    setOpen(false);
  };

  return (
    <>
      <YesNo
        message={'Êtes-vous sûr de vouloir supprimer les éléments sélectionnés ?'}
        open={open}
        setOpen={setOpen}
        setResult={(result) => {
          if (result === 1) {
            handleConfirmDelete();
          } else {
            handleCancelDelete();
          }
        }}
      />
      <StyledRoot
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} résident(s) sélectionné(s)
          </Typography>
        ) : (<>
          <Grid>
              <FormControlLabel
                              checked={filterActive}
                              onChange={onFilterActive}
                              control={<Switch />}
                              label={<Label color={filterActive ? 'success' : 'error'}>{filterActive ? t('resident-list-active') : t('resident-list-inactive')}</Label>}
              />
              <FormControlLabel
                              checked={filterAlive}
                              onChange={onFilterAlive}
                              control={<Switch />}
                              label={<Label color={filterAlive ? 'success' : 'error'}>{filterAlive ? t('resident-list-customer') : t('resident-list-dead')}</Label>}
              />
          </Grid>
              <StyledSearch
              sx={{ width: '82%' }}
              value={filterName}
              onChange={onFilterName}
              placeholder={t('resident-list-search-resident')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
            </>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteClick}>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip>
        )}
      </StyledRoot>
    </>
  );
}
