import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    TextField,
    Alert,
    Button,
    Typography
} from '@mui/material';
import { Label } from '@mui/icons-material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { changePassword, login } from '../../../actions/authActions';

import Iconify from '../../../components/iconify';

export default function SetPassword() {

    const navigate = useNavigate();
    const { guid } = useParams();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [error, setError] = useState(null);


    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangePassword2 = (e) => {
        const password2 = e.target.value;
        setPassword2(password2);
    };

    const handleClick = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await dispatch(changePassword(guid, password));
        } catch (error) {
            setError(t('set-password-error'));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    name="password"
                    label={t('login-password')}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={onChangePassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    name="password"
                    label={t('set-password-confirm')}
                    type={showPassword2 ? 'text' : 'password'}
                    value={password2}
                    onChange={onChangePassword2}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword2(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {
                    error && <Alert sx={{ m: 2 }} severity="error">{error}</Alert>
                }

                {
                    (password.length > 0 && password2.length > 0 && password !== password2) &&

                    <Alert severity="error">{t('set-password-doesnt-match')}</Alert>
                }

                <LoadingButton
                    fullWidth
                    size="large"
                    disabled={(!password || !password2) || password !== password2}
                    type="submit"
                    variant="contained"
                    onClick={handleClick}
                    loading={isLoading}>
                    {t('set-password-save')}
                </LoadingButton>


                <Button onClick={() => { navigate('/') }}>
                    <ArrowBackIcon />
                    {t('set-password-back')}
                </Button>

            </Stack>

        </>
    );
}
