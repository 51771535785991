import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';

export const fetchDeliveryList = async (customerId) => {
    try {
        const response = await request('GET', 'ScanSession', { customerId });
        return response.data
    } catch (error) {
        console.error('Error in fetchDeliveryList:', error);
        throw error;
    }
};

export const fetchDeliveryByGUID = async (guid) => {
    try {
        const response = await request('GET', `ScanSession/GUID/${guid}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchDeliveryByGUID:', error);
        throw error;
    }
};

export const fetchDeliveryWorkerDetail = async (workerId, scanSessionId) => {
    try {
        const response = await request('GET', `ScanSession/WorkerDetail/${workerId}/${scanSessionId}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchDeliveryWorkerDetail:', error);
        throw error;
    }
};