import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { DataGridPro } from '@mui/x-data-grid-pro';
import {
    Box,
    Card,
    CircularProgress,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import Label from '../../components/label';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import ResidentClothesToolBar from '../../sections/@dashboard/residents/ResidentClothesToolBar';

export default function ResidentClothing({ resident }) {

    const [filterName, setFilterName] = useState('');
    const { t, i18n } = useTranslation();
    const columns = [
        { field: 'sizeName', headerName: t('delivery-page-size') },
        { field: 'brandName', headerName: t('delivery-page-brand') },
        { field: 'colorName', headerName: t('delivery-page-color') },
        { field: 'storageType', headerName: t('delivery-page-storage'), valueGetter: (params) => params.rowNode.type === 'group' ? "" : params.row.storageType === 1 ? t('resident-page-fold') : t('resident-page-hanged') },
        { field: 'status', headerName: t('resident-page-status'), renderCell: (params) => params.rowNode.type === 'group' ? "" : params.row.inCleaning ? (<Label color={'primary'}>{t('resident-page-status-processed')}</Label>) : (<Label color={'success'}>{t('resident-page-status-available')}</Label>) },
        { field: 'notes', headerName: t('resident-page-notes') },
    ];

    const getRowId = (row) => {
        return row.barcode;
    }

    const getTreeDataPath = (clothe) => {
        return [clothe.productName, clothe.barcode]
    }

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    return (
        <Grid item xs={12}>
            <Card sx={{ padding: 2 }}>
                <Typography variant="h4">
                    {t('resident-page-wardrobe')} {resident?.webClothes.length > 0 ? `(${resident.webClothes.length})` : ''}
                </Typography>

                <ResidentClothesToolBar filterName={filterName} onFilterName={handleFilterByName} />

                {resident ? (
                    <DataGridPro
                        rows={resident.webClothes.filter(webClothe => !filterName || webClothe.productName.toLowerCase().includes(filterName.toLowerCase())) || []}


                        columns={columns}
                        pageSize={5}
                        getRowId={getRowId}
                        autoHeight
                        treeData
                        hideFooterRowCount
                        disableRowSelectionOnClick
                        getTreeDataPath={getTreeDataPath}
                        groupingColDef={{
                            headerName: t('resident-page-name'),
                            flex: 1
                        }}
                        loading={resident.webClothes === undefined}
                        components={{
                            NoRowsOverlay: () => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%">
                                    <CustomNoRowsOverlay text={t('resident-page-no-clothes')} />
                                </Box>
                            ),
                        }}
                    />
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%">
                        <CircularProgress />
                    </Box>
                )}
            </Card>
        </Grid>
    );
}
