import { Navigate, Route, Routes } from 'react-router-dom'; // Update import
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/User/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/Dashboard/DashboardAppPage';
import ResidentPage from './pages/Resident/ResidentPage';
import DeliveryPage from './pages/Delivery/DeliveryPage';
import DeliveryDetailPage from './pages/Delivery/DeliveryDetailPage';
import ResidentDetailPage from './pages/Resident/ResidentDetailPage';
import UserDetailPage from './pages/User/UserDetailPage';
import ContactForm from './pages/ContactForm';
import SetPassword from './sections/auth/login/SetPassword';
import InvoicePage from './pages/Invoice/InvoicePage';
import InvoiceDetailPage from './pages/Invoice/InvoiceDetailPage';
import PublicResidentDetails from './pages/Resident/PublicResidentDetails';
import ForgetPassword from './sections/auth/login/ForgetPassword';
import AdminCalendar from './pages/B2C/AdminCalendar';
import DailyTasks from './pages/B2C/DailyTasks';
import CollectRequest from './pages/B2C/CollectRequest';
import CollectDeliveryValidation from './pages/B2C/CollectDeliveryValidation';
import CollectCancel from './pages/B2C/CollectCancel';

export default function Router() {
  const user = useSelector((state) => state.auth.user);

  function AuthGuard({ children }) {
    return user ? children : <Navigate to="/login" />;
  }
  return (
    <Routes>
      <Route path="/" element={<AuthGuard><DashboardLayout /></AuthGuard>}>
        <Route index element={<DashboardAppPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="invoices" element={<InvoicePage />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="deliveries" element={<DeliveryPage />} />
        <Route path="deliveries/:id" element={<AuthGuard><DeliveryDetailPage /></AuthGuard>} />
        <Route path="residents/:id" element={<AuthGuard><ResidentDetailPage /></AuthGuard>} />
        <Route path="invoices/:id" element={<AuthGuard><InvoiceDetailPage /></AuthGuard>} />
        <Route path="residents/new-resident" element={<AuthGuard><ResidentDetailPage /></AuthGuard>} />
        <Route path="residents" element={<ResidentPage />} />
        <Route path="users" element={<UserPage />} />
        <Route path="users/:id" element={<AuthGuard><UserDetailPage /></AuthGuard>} />
        <Route path="contact-form" element={<AuthGuard><ContactForm /></AuthGuard>} />
      </Route>


      <Route path="calendar" element={<AdminCalendar />} />
      <Route path="calendar/:date" element={<DailyTasks />} />

      <Route path="login" element={user ? (user.password ? <Navigate to="/" /> : <Navigate to="/set-password" />) : <LoginPage />} />

      <Route path="set-password/:guid" element={user ? <Navigate to="/" /> : <LoginPage setPassword={1} />} />

      <Route path="forget-password" element={user ? <Navigate to="/" /> : <ForgetPassword />} />

      <Route path="forget-password/:guid" element={user ? <Navigate to="/" /> : <LoginPage setPassword={1} />} />

      <Route path="resident/:guid" element={<PublicResidentDetails />} />

      <Route path="demande-collecte/:guid" element={<CollectRequest />} />
      <Route path="annulation-collecte/:guid" element={<CollectCancel />} />

      <Route path="validate/:id" element={<CollectDeliveryValidation />} />
    
      <Route index element={user ? <Navigate to="/" /> : <LoginPage />} />

      <Route path="*">
        <Route index element={<Navigate to="/" />} />
        <Route path="404" element={<Page404 />} />
      </Route>
    </Routes>
  );
}
