import React, { useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import md5 from 'md5';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Breadcrumbs,
  Button,
  Grid,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { LoadingButton } from '@mui/lab';
import Label from '../../components/label';
import { fetchDeliveryByGUID, fetchDeliveryWorkerDetail} from '../../services/deliveryService';
import Page404 from '../Page404';

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  
  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

const fetchDeliveryWorkerDetails = async (workerId, scanSessionId) => {
  try {
    const data = fetchDeliveryWorkerDetail(scanSessionId,workerId);
    return data;
  } catch (error) {
    return [];
  }
};

const RowDetail = (data,t) =>
{
  return(
    <>
    <Grid item xs={12}>
                <Grid container>
                    <Grid item>
                        <h6>{t('delivery-page-cleaned-clothes')}</h6>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>{t('delivery-page-clothes')}</TableCell>
                        <TableCell>{t('delivery-page-size')}</TableCell>
                        <TableCell>{t('delivery-page-brand')}</TableCell>
                        <TableCell>{t('delivery-page-color')}</TableCell>
                        <TableCell>{t('delivery-page-storage')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {data && data.map(row => (
                    <TableRow>
                      <TableCell><CheckroomIcon/></TableCell>
                      <TableCell component="th" scope="row">
                        {row.productName}
                      </TableCell>
                      <TableCell>{row.sizeName}</TableCell>
                      <TableCell>{row.brandName}</TableCell>
                      <TableCell>{row.colorName}</TableCell>
                      <TableCell>{row.storageType}</TableCell>
                    </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
    </Grid>
    </>
  )
}


export default function DeliveryDetailPage() {
  const { id } = useParams();
  const [delivery, setDelivery] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const connectedUser = useSelector((state) => state.auth.user);
  const { t, i18n } = useTranslation();
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDeliveryDetails = async () => {
      try {
        const data = await fetchDeliveryByGUID(id);
        setDelivery(data);
        setIsLoading(false);
        setError(null);
      } catch (error) {
        setIsLoading(false);
        setError('Error fetching delivery details. Please try again later.');
      }
    };
    fetchDeliveryDetails();
  }, [id]);

  // Render the component structure without resident data
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Button style={{ display: 'flex', alignItems: 'center' }} component={Link} to="/deliveries">
          <NavigateBeforeIcon style={{ marginRight: '8px' }} /> {t('delivery-list-delivery')}
        </Button>
        {delivery && <Typography color="textPrimary">{delivery.datetimeInputCreation ? format(new Date(delivery.datetimeInputCreation), "PPpp",{locale: frLocale}) : ""}</Typography>}
      </Breadcrumbs>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 1, p: 2 }}>
              {!isLoading ? (
                <>
                <Grid container alignItems="center">
                    <Grid item sx={{ pl: 2, mt: 1, mb: 1 }}>
                        <h4>{t('delivery-page-delivery-details')}</h4>
                    </Grid>
                </Grid>
                
                  <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>{t('delivery-page-name')}</TableCell>
                        <TableCell>{t('delivery-page-first-name')}</TableCell>
                        <TableCell>{t('delivery-page-product-number')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {delivery && delivery.delivery.map(row => (
                    <ExpandableTableRow
                      key={row.workerId}
                      expandComponent={<TableCell colSpan="5">{RowDetail(row.clothes,t)}</TableCell>}
                    >
                      <TableCell component="th" scope="row">
                        {row.lastName}
                      </TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.count}</TableCell>
                    </ExpandableTableRow>
                  ))}
                  </TableBody>
                  </Table>
                </TableContainer></>)
                :
                (
                  <>
                <Grid container display="flex"
      justifyContent="center">
                    <Grid item sx={{ pl: 2, pt: 2, mt: 1, mb: 1 }}>
                        <h4><CircularProgress/></h4> 
                    </Grid>
                </Grid>
                </>
                )
                }
                
          </Grid>
        </Grid>
        </Card>
    </>
  );
}
