import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';


export const fetchWorkerList = async (customerId) => {
    const response = await request('GET', 'Worker', { customerId, includeInactives: 'true' })
    return response.data;
};

export const fetchWorker = async (workerId) => {
    const response = await request('GET', `Worker/${workerId}`);
    return response.data;
};

export const fetchWorkerByGUID = async (workerGUID) => {
    const response = await request('GET', `Worker/GUID/${workerGUID}`);
    return response.data;
};

export const donateWorkerClothes = async (workerGUID) => {
    const response = await request('GET', `Worker/DonateClothes/${workerGUID}`);
    return response.data;
};

export const DeleteWorker = async (id) => {
    try {
        await request('DELETE', `Worker/${id}`);
        return true
    } catch (error) {
        return false
    }
};

export const UpsertWorker = async (workerId, workerDTO) => {
    const response = workerId > 0 ? await request('PUT', `Worker/${workerId}`, workerDTO) : await request('POST', `Worker`, workerDTO);
    return response.data;
};
