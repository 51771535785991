import { React, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import ConfettiExplosion from 'react-confetti-explosion';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import { Box, InputLabel, Typography } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../components/logo';
import { deleteCollect, getCustomerInformation, postCollect } from '../../services/collectService';
import YesNo from '../YesNo';

dayjs.extend(customParseFormat);
dayjs.locale('fr');

function CollectRequest() {

    const { guid } = useParams();
    const [customerRequest, setCustomerRequest] = useState();
    const [isExploding, setIsExploding] = useState();

    useEffect(() => {
        fetchData();
    }, [guid]);

    const fetchData = async () => {
        try {
            const customerRequest = await getCustomerInformation(guid);
            setCustomerRequest(customerRequest)
        } catch (error) {
            toast.error('Une erreur est survenue lors de la récupération des informations liées au sac.');
        }
    };

    const postRequest = async () => {
        const request = toast.promise(
            postCollect({
                RequestDate: new Date().toISOString(),
                IdCustomer: customerRequest.idCustomer,
                Quantity: customerRequest.quantity,
                PlannedCollectionDate: customerRequest.plannedCollectionDate,
                Comment: customerRequest.comment
            }),
            {
                loading: 'Envoi de la demande...',
                success: (result) => {
                    setIsExploding(1)
                    setCustomerRequest(result)
                    return <><b>{`Merci pour votre demande ! L'équipe de CoolPressing passera chercher vos sac le  ${dayjs(customerRequest.plannedCollectionDate).format('dddd D MMMM YYYY')}`}</b></>;
                },
                error: <b>Une erreur est survenue lors de la demande de collecte, merci de réessayer ultérieurement.</b>,
            },
            {
                success: {
                    duration: 7000,
                },
            }
        );
    }

    const delRequest = async () => {


        toast.promise(
            deleteCollect(customerRequest.id),
            {
                loading: 'Annulation de la demande de collecte...',
                success: () => {

                    setCustomerRequest((prevData) => ({
                        ...prevData,
                        id: null,
                        comment: document.getElementById('comment').value
                    }))

                    return <><b>{`La demande de collecte a été annulée`}</b></>;
                },
                error: <b>Une erreur est survenue lors de l'annulation de la demande de collecte, merci de réessayer ultérieurement.</b>,
            },
            {
                success: {
                    duration: 5000,
                },
            }
        );
    }

    return (
        <Container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', direction: 'column', height: '100%', backgroundColor: '#18407F' }}>
            {
                customerRequest && customerRequest.id > 0 && isExploding && <ConfettiExplosion force={0.8} duration={3000} particleCount={250} />
            }
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h2' sx={{ color: 'white' }}>
                            Demande
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Coordonnées
                        </InputLabel>
                        <TextField
                            sx={{ input: { color: 'white' } }}
                            fullWidth
                            value={customerRequest ? `${customerRequest.customerLastName ? customerRequest.customerLastName : ''} ${customerRequest.customerFirstName ? customerRequest.customerFirstName : ''} ${customerRequest.customerCompanyName ? `${customerRequest.customerCompanyName}` : ''}` : ""}
                            variant="standard"
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Adresse de livraison
                        </InputLabel>
                        <TextField
                            sx={{ input: { color: 'white' } }}
                            fullWidth
                            variant="standard"
                            InputProps={{ readOnly: true }}
                            value={customerRequest ? customerRequest.customerAddress : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Date et heure de l'enlèvement
                        </InputLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            disabled
                            InputProps={{ style: { backgroundColor: 'white', borderRadius: '1px' } }}
                            value={customerRequest && customerRequest.plannedCollectionDate ? dayjs(customerRequest.plannedCollectionDate).format('dddd D MMMM YYYY') : 'À definir'}
                        />

                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Commentaire
                        </InputLabel>
                        <TextField
                            id="comment"
                            fullWidth
                            sx={{ backgroundColor: 'white', borderRadius: 1 }}
                            multiline
                            rows={7}
                            variant="outlined"
                            value={customerRequest && customerRequest.comment}
                            disabled={customerRequest && customerRequest.id > 0}
                            onChange={(e) =>
                                setCustomerRequest((prevData) => ({ ...prevData, comment: e.target.value }))
                            } />
                    </Grid>
                </Grid>
                {
                    customerRequest && customerRequest.id ?
                        <Button
                            onClick={() => {
                                delRequest()
                            }}
                            variant="contained"
                            color="error"
                            sx={{ textTransform: 'none' }}
                            style={{ width: '100%', marginTop: 16 }}>
                            Annuler la demande de collecte
                        </Button>
                        :
                        <Button
                            onClick={() => {
                                postRequest()
                            }}
                            variant="contained"
                            sx={{ textTransform: 'none' }}
                            style={{ backgroundColor: '#06C694', width: '100%', marginTop: 16 }}>
                            Valider
                        </Button>
                }
            </form>
        </Container >
    );
}

export default CollectRequest;
