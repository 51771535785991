import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import CountUp from 'react-countup';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchInvoiceList, fetchLastInvoice } from '../../services/invoiceService';
import { fetchBankAccountsByCompany } from '../../services/BankAccountService';
import CustomNoRowsOverlay from '../Resident/CustomNoRowsOverlay';

export default function InvoiceToPay() {

    const user = useSelector((state) => state.auth.user);
    const { t, i18n } = useTranslation();

    const [invoice, setInvoice] = useState(null);
    const [invoiceList, setinvoiceList] = useState([]);
    const [loading, setLoading] = useState(null);
    const [bankAccounts, setBankAccounts] = useState();
    const navigate = useNavigate();
    const [nbPieces, setNbPieces] = useState(0);

    useEffect(() => {

        setLoading(true)
        const fetchData = async () => {
            try {
                const data = await fetchLastInvoice(user.idCustomer);
                setInvoice(data);
                const dataList = await fetchInvoiceList(user.idCustomer);
                setinvoiceList(dataList);
                setLoading(false)
            } catch (error) {
                setInvoice(null);
                setLoading(false)
            }
        };

        const fetchBankAccounts = async () => {
            setBankAccounts(await fetchBankAccountsByCompany(user.idCustomer));
        };

        fetchData();
        fetchBankAccounts();
    }, []);

    const priceToString = (price) => {
        return price.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })
    }
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const handleClickPayNow = () => {
        navigate(`/invoices/${invoice.guid}`)
    }

    const generatePaymentQRCode = () => {
        if (!bankAccounts || bankAccounts.length === 0) {
            return '';
        }
        const bankAccount = bankAccounts[0];
        return `BCD
                002
                1
                SCT
                ${bankAccount.bic}
                BeeClean Web
                ${bankAccount.iban}
                EUR${invoice.toPay}
                ${invoice.paymentCommunication}
                `
    }

    const isDirectInvoicing = () => {
        return invoice && (invoice.directInvoicingInfo && invoice.directInvoicingInfo.length > 0);
    }

    const getNbPieces = () => {
        const nbPieces = !isDirectInvoicing() ? invoice.nbPieces : invoiceList.filter((i) => i.status === 2).reduce((n, {nbPieces}) => n + nbPieces, 0);
        return nbPieces;
    }
    return (
        <>
            <Typography mb={1}>💳 {t('invoice-to-pay-title')}</Typography>
            <Card sx={{ borderLeft: '10px solid #349ECC', minHeight: '225px', display: 'flex', alignItems: 'center' }}>
                {invoice ?
                    <Grid pl={3} container justifyContent='start' >
                        <Grid sx={{ backgroundColor: '' }} container item xs={8} >
                            <Grid item xs={12}>
                                {!isDirectInvoicing() ?
                                <Typography variant="h6" sx={{ color: '#7E7E7E', fontWeight: 'bold' }}>{t('invoice-to-pay-invoice')} #{`${invoice.year}-${zeroPad(invoice.no, 3)}`} {t('invoice-to-pay-to-pay')}</Typography>
                                :
                                <Typography variant="h6" sx={{ color: '#7E7E7E', fontWeight: 'bold' }}>{invoiceList.filter((i) => i.status === 2).length} {t('invoice-to-pay-invoices')} {t('invoice-to-pay-to-pay')}</Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!isDirectInvoicing() ?
                                <Typography variant="h1">
                                    {priceToString(invoice.toPay)}
                                </Typography>
                                :
                                <Typography variant="h1">
                                    {priceToString(invoiceList.filter((i) => i.status === 2).reduce((n, {totalPriceTaxIncl}) => n + totalPriceTaxIncl, 0))}
                                </Typography>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!isDirectInvoicing() &&
                                <Typography variant="body1">
                                    {t('invoice-to-pay-due-date')} <span style={{ fontWeight: 'bold' }}>{format(new Date(invoice.dueDate), "dd/MM/yyyy", { locale: frLocale })}</span>
                                </Typography>
                                }      
                            </Grid>
                            <Grid item xs={12} sx={{ color: '#349ECC' }}>
                            {!isDirectInvoicing() ?
                                <Typography variant="body2">{t('invoice-to-pay-spared')}</Typography>
                                :
                                <Typography variant="body2">{invoiceList.filter((i) => i.status === 2).length <= 1 ? t('invoice-to-pay-spared') : t('invoice-to-pay-spared-plural')}</Typography>
                            }
                            {!isDirectInvoicing() ?
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TimeToLeaveIcon />
                                        <CountUp end={getNbPieces()*3.4/130} duration={2} /> Kg CO²
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FlashOnIcon />
                                        <CountUp end={getNbPieces()*0.01} duration={2} decimal={2} /> KWh
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AccessTimeIcon />
                                        <CountUp end={getNbPieces()*0.25} duration={2} decimal={2} /> min
                                    </Grid>
                                </Grid>
                                :
                                <Grid container>
                                    <Grid item xs={3}>
                                        <TimeToLeaveIcon />
                                        <CountUp end={(getNbPieces()*3.4/130)*1000} duration={2} /> g CO²
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FlashOnIcon />
                                        <CountUp end={(getNbPieces()*0.01)*1000} duration={2} decimal={2} /> Wh
                                    </Grid>
                                    <Grid item xs={3}>
                                        <AccessTimeIcon />
                                        <CountUp end={getNbPieces()*0.25} duration={2} decimal={2} /> min
                                    </Grid>
                                </Grid>
                            }
                            </Grid>

                        </Grid>
                        {bankAccounts && !isDirectInvoicing() &&
                            <Grid sx={{ backgroundColor: '' }} container item xs={4} justifyContent='center' alignContent='center' textAlign='center'>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('invoice-to-pay-pay-now')}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ margin: '8px auto', maxWidth: '40%', width: '100%' }}>
                                    <QRCode
                                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                        value={generatePaymentQRCode()}
                                        viewBox={`0 0 256 256`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button sx={{ backgroundColor: 'black', textTransform: 'none' }} variant='contained' onClick={() => { handleClickPayNow() }}>{t('invoice-to-pay-pay-now')}</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid> :

                    loading ? <CircularProgress /> :
                        <>
                            <CustomNoRowsOverlay text={t('dashboard-all-invoice-paid')} />
                        </>

                }
            </Card >
        </>
    );
}
