import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { CheckBox } from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Label from '../../components/label';
import { donateWorkerClothes, fetchWorkerByGUID } from '../../services/workerService';
import Page404 from '../Page404';
import ResidentInformations from './ResidentInformations';
import ResidentContacts from './ResidentContacts';
import ResidentClothing from './ResidentClothing';
import YesNo from '../YesNo';

export default function ResidentDetailPage() {
  const { id } = useParams();
  const [resident, setResident] = useState();
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [edited, setEdited] = useState(false);

  const connectedUser = useSelector((state) => state.auth.user);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchResidentDetails = async () => {
      try {
        if (id) {
          const data = await fetchWorkerByGUID(id);
          setResident(data);
        } else {
          setResident({ isActive: 1, isAlive: 1, webClothes: [] })
        }
      } catch (error) {
        toast.error('Error fetching resident details. Please try again later.')
      }
    };
    fetchResidentDetails();
  }, [id]);

  const handleInputChange = (v, key) => {
    
    // if (key === 'isAlive' && !v) {
    //   setOpen2(true)
    // }

    setEdited(true)

    setResident({ ...resident, [key]: v });
  };

  const handleBackClick = () => {
    if (edited) {
      setOpen(true)
    } else {
      navigate('/residents')
    }
  }

  return (
    <>
      {/* <YesNo
        message={t('resident-page-give-clothes')}
        open={open2}
        setOpen={setOpen2}
        setResult={async (result) => {
          if (result === 1) {
            try {
              await donateWorkerClothes(id)
              toast.success(t('resident-page-thanks-donation'))
            }
            catch (error) {
              toast.error(t('resident-page-error-donation'))
            }
          } else {
            setOpen(false)
          }
        }} /> */}
      <YesNo
        message={t('resident-page-exit-no-save')}
        open={open}
        setOpen={setOpen}
        setResult={(result) => {
          if (result === 1) {
            setOpen(false)
            navigate('/residents')
          } else {
            setOpen(false)
          }
        }}
      />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Button style={{ display: 'flex', alignItems: 'center' }} onClick={handleBackClick}>
          <NavigateBeforeIcon /> {t('resident-page-residents')}
        </Button>
        {resident && <Typography color="textPrimary">{resident.firstname} {resident.lastname}</Typography>}
      </Breadcrumbs>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <ResidentInformations resident={resident} setResident={setResident} handleInputChange={handleInputChange} setEdited={setEdited}  readonly={connectedUser.type !== 1}/>
        </Grid>
        <Grid item xs={8}>
          <ResidentContacts resident={resident} setResident={setResident} readonly={connectedUser.type !== 1}/>
        </Grid>
        <Grid item xs={12}>
          <ResidentClothing resident={resident}  readonly={connectedUser.type !== 1}/>
        </Grid>
      </Grid>
    </>
  );
}
