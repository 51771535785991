import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';



export const fetchBankAccountsByCompany = async (customerId) => {
    try {
        const response = await request('GET', `BankAccount/GetAllByIdCustomer/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchBankAccountsByCompany:', error);
        throw error;
    }
};

