import { loginAPI, changePasswordAPI } from '../services/authService'

export const login = (username, password) => (dispatch) => {

  return loginAPI(username, password).then(
    (data) => {

      if (data.length <= 0)
        return Promise.reject();

      const user = data[0];

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: user,
      });

      localStorage.setItem('user', JSON.stringify(user));
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: 'LOGIN_FAIL',
        payload: error,
      });
      return Promise.reject();
    }
  );
};

export const changePassword = (guid, password) => (dispatch) => {

  return changePasswordAPI(guid, password).then((data) => {

    if (data.length <= 0)
      return Promise.reject();

    const user = data;

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: user,
    });

    localStorage.setItem('user', JSON.stringify(user));
    return Promise.resolve();
  },
    (error) => {
      dispatch({
        type: 'LOGIN_FAIL',
        payload: error,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  return {
    type: 'LOGOUT',
  };
};
