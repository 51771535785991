import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';


export default function ResidentCount({ data }) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (
        <>
            <Typography mb={1} sx={{ visibility: 'hidden' }}>I</Typography>
            <Card sx={{ minHeight: '225px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                {data &&
                    <Grid container justifyContent='center' spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>
                                {t('resident-count-title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h2' sx={{ width: '100%' }}>
                                👴<CountUp end={data.totalWorkerWoman + data.totalWorkerMan + (data.totalWorkerNoBinary ? data.totalWorkerNoBinary : 0)} duration={1} />👵
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body6">
                                <span style={{ fontWeight: 'bold' }}>{t('resident-count-resident')} </span>
                                {t('resident-count-text')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button sx={{ backgroundColor: 'black', textTransform: 'none' }} onClick={() => { navigate('/residents/new-resident') }} variant='contained'>{t('resident-count-new-resident')}</Button>
                        </Grid>
                    </Grid>
                }
            </Card>
        </>
    );
}
