import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Label from '../../components/label';
import { fetchWorkerByGUID } from '../../services/workerService';
import Page404 from '../Page404';
import ResidentInformations from './ResidentInformations';
import ResidentContacts from './ResidentContacts';
import ResidentClothing from './ResidentClothing';
import Logo from '../../components/logo';

export default function PublicResidentDetails() {
    const { guid } = useParams();
    const [resident, setResident] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const fetchResidentDetails = async () => {
            try {
                const data = await fetchWorkerByGUID(guid);
                setResident(data);
                setError(null);
            } catch (error) {
                setError('Error fetching resident details. Please try again later.');
            }
        };
        fetchResidentDetails();
    }, [guid]);


    return (
        <>
            {
                resident ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Logo />
                        </Box>
                        <ResidentClothing resident={resident} />
                    </>

                    : <CircularProgress />
            }
        </>
    );
}
