import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';


export const fetchDashboardData = async (customerId) => {
    try {
        const response = await request('GET', `Dashboard/GetWebStatsDTO/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchDashboardData:', error);
        throw error;
    }
};