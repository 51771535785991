import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';

export default function CountrySelect({ setValue, selectedCode, readonly, setCountryName }) {

    const index = countries.findIndex(element => { return (element.code === selectedCode) });

    return (
        <Autocomplete
            size="small"
            id="country-select-demo"
            value={countries[index] || null}
            options={countries}
            disableClearable
            getOptionLabel={(option) => option.code}
            style={{ minWidth: '100%', borderRadius: '30px' }}
            onChange={(event, newInputValue) => {
                setValue(newInputValue.code);
                setCountryName(newInputValue.code);
            }}
            renderOption={(props, option) => (
                <Box  {...props} >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <img
                                loading="lazy"
                                src={`https://flagcdn.com/w20/${option.code === 'EN' ? 'gb' : option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code === 'EN' ? 'gb' : option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {option.code}
                        </Grid>
                    </Grid>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ width: '50%'}}
                    disabled={readonly}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

const countries = [
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        suggested: true,
    },
    { code: 'EN', label: 'Anglais', phone: '44' },
    { code: 'NL', label: 'Pays-bas', phone: '31' },
];