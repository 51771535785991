import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';


export const deleteContactPerson = async (id) => {
    const response = await request('DELETE', `ContactPerson/${id}`);
    return response.data;
};

export const UpsertContactPerson = async (id, contactPersonDTO) => {
    const response = id > 0 ? await request('PUT', `ContactPerson/${id}`, contactPersonDTO) : await request('POST', `ContactPerson`, contactPersonDTO);
    return response.data;
};