import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';


export const fetchInvoiceByGUID = async (invoiceGUID) => {
    try {
        const response = await request('GET', `Invoice/GUID/${invoiceGUID}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchInvoiceByGUID:', error);
        throw error;
    }
};

export const fetchInvoiceList = async (customerId) => {
    try {
        const customerView = 1;
        const response = await request('GET', 'Invoice', { customerId, customerView });
        return response.data;
    } catch (error) {
        console.error('Error in fetchInvoiceList:', error);
        throw error;
    }
};

export const fetchLastInvoice = async (customerId) => {
    const response = await request('GET', `Invoice/LastInvoice/${customerId}`);
    return response.data;
};

export const fetchInvoicePieces = async (invoiceId, customerId) => {
    const response = await request('GET', `Invoice/PiecesCount/${invoiceId}`);
    return response.data;
};