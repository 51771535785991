import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from "react-i18next";
import Iconify from '../../components/iconify'


const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default function InvoiceListToolBar({
    numSelected,
    filterName,
    onFilterName,
    selected,
    invoiceList,
    setInvoiceList,
    setSelected,
    selectedType,
    setSelectedType
}) {
    const { t, i18n } = useTranslation();
    const handleDeleteClick = () => {
        const updatedInvoiceList = invoiceList.filter((user) => !selected.includes(user.id));
        setSelected([]);
        setInvoiceList(updatedInvoiceList);
    };

    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}>

            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <>
                <StyledSearch
                    sx={{ width: '65%' }}
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={t('invoice-list-search-invoice')}
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    }
                />
                <FormControl>
                <FormLabel>{t('invoice-list-invoice-type')}</FormLabel>
                <RadioGroup
                    row
                >
                    <FormControlLabel value="-1" control={<Radio  onChange={setSelectedType} checked={selectedType === "-1"} />} label={t('invoice-list-all-invoice')} />
                    <FormControlLabel value="0"   control={<Radio    onChange={setSelectedType} checked={selectedType === "0"}/>} label={t('invoice-list-all-house')} />
                    <FormControlLabel value="1"  control={<Radio   onChange={setSelectedType} checked={selectedType === "1"}/>} label={t('invoice-list-all-residents')} />
                </RadioGroup>
                </FormControl>
                </>
            )}

            
        </StyledRoot>
    );
}
