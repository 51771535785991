import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { Grid, Container, Typography, Paper, Box } from '@mui/material';
import InvoiceToPay from './InvoiceToPay';
import NextPickUp from './NextPickUp';
import Annoncement from './Annoncement';
import LateInvoices from './LateInvoices';
import DidYouKnow from './DidYouKnow';
import ResidentCount from './ResidentCount';
import CustomNoRowsOverlay from '../Resident/CustomNoRowsOverlay';
import { fetchDashboardData } from '../../services/dashboardService';

export default function DashboardAppPage() {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const { t, i18n } = useTranslation();

  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDashboardData(user.idCustomer);
      setDashboardData(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Cool-Pressing </title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InvoiceToPay />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NextPickUp data={dashboardData}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Annoncement/>
        </Grid>
        <Grid item xs={12} sm={6}>
          {1 ? <LateInvoices /> : <Paper sx={{ height: '225px' }}><CustomNoRowsOverlay text={t('dashboard-invoice-paid')} /></Paper>}
        </Grid>
        <Grid item xs={12} sm={3}>
          <DidYouKnow data={dashboardData}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ResidentCount data={dashboardData}/>
        </Grid>
      </Grid>
    </>
  );
}
