import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { useTranslation } from "react-i18next";

import axios from 'axios';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';

// sections
import { ResidentListHead, ResidentListToolbar } from '../../sections/@dashboard/residents';
// mock
import { fetchWorkerList } from '../../services/workerService';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, paramActive, paramDead) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

    return filter(array, (_user) => {
      const firstNameMatch = _user.firstname ? _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false;
      const lastNameMatch = _user.lastname ? _user.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false;
      const companyMatch = _user.company ? _user.company.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false;
      const activeMatch = _user.isActive === paramActive;
      const deadMatch = _user.isAlive === paramDead;

      const retValue = (activeMatch && deadMatch && (query ? firstNameMatch || lastNameMatch || companyMatch : true));
      return retValue;
    });

  // return stabilizedThis.map((el) => el[0]);
}

export default function ResidentPage() {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterActive, setFilterActive] = useState(true);
  const [filterAlive, setFilterAlive] = useState(true);
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.auth.user);

  const TABLE_HEAD = [
    { id: 'picture', label: '', alignRight: false },
    { id: 'status', label: t('resident-list-title-status'), alignRight: false },
    { id: 'name', label: t('resident-list-title-name'), alignRight: false },
    { id: 'firstname', label: t('resident-list-title-first-name'), alignRight: false },
    { id: 'cabinetNo', label: t('resident-list-title-floor'), alignRight: false },
    { id: 'lockerNo', label: t('resident-list-title-room'), alignRight: false },
    { id: 'inputDate', label: t('resident-list-title-date-creation'), alignRight: false },
    { id: 'outputDate', label: t('resident-list-title-date-exit'), alignRight: false },
  ];
  
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("FilterAlive", filterAlive)) {
      const boolValue = (localStorage.getItem("FilterAlive", filterAlive) === "true"); 
      setFilterAlive(boolValue);
    }
    if (localStorage.getItem("FilterActive", filterActive)) {
      const boolValue = (localStorage.getItem("FilterActive", filterAlive) === "true"); 
      setFilterActive(boolValue);
    }

    const fetchData = async () => {
      try {
        const data = await fetchWorkerList(user.idCustomer);
        setUserList(data.sort((a, b) => b.id - a.id));
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, id) => {

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

  };

  const handleRowClick = (event, id) => {
    navigate(`/residents/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterActive = (event) => {
    setPage(0);
    setFilterActive(event.target.checked);
    localStorage.setItem("FilterActive", event.target.checked)
  };

  const handleFilterAlive = (event) => {
    setPage(0);
    setFilterAlive(event.target.checked);
    localStorage.setItem("FilterAlive", event.target.checked)
  };

  const handleNewResident = () => {
    navigate('/residents/new-resident')
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName, filterActive, filterAlive);
  const isNotFound = !filteredUsers.length && !!filterName;

  const getResidentAvatar = (idBlobNavigation, civility) => {
    if (idBlobNavigation != null && idBlobNavigation.id > 0) {
      return idBlobNavigation.path + idBlobNavigation.name;
    }
    let avatarIndex;
    switch (civility) {
      case 1:
        avatarIndex = 2;
        break;
      case 2:
        avatarIndex = 3;
        break;
      default:
        avatarIndex = 0;
        break;
    }
    return `/assets/images/avatars/avatar_${avatarIndex}.jpg`;
  }

  return (
    <>

      <Helmet>
        <title> {t('resident-list-residents')} </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
          {t('resident-list-residents')}
          </Typography>
          {
            user.type === 1 &&
            <Button onClick={handleNewResident} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              {t('resident-list-new-resident')}
            </Button>
          }
        </Stack>

        <Card >
          <ResidentListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} filterActive={filterActive} onFilterActive={handleFilterActive} filterAlive={filterAlive} onFilterAlive={handleFilterAlive} selected={selected} setSelected={setSelected} userList={userList} setUserList={setUserList} />


          {userList ?

            <Scrollbar >
              <TableContainer sx={{}}>
                <Table>
                  <ResidentListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={userList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, firstname, lastname, civility, isActive, lockerNo, cabinetNo, inputDate, outputDate, guid, idBlobNavigation } = row;
                      const selectedUser = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                          onClick={(event) => {
                            if (event.target.tagName !== 'INPUT') {
                              handleRowClick(event, guid);
                            }
                          }}
                        >

                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => { event.stopPropagation(); handleClick(event, id) }} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={id.toString()} src={getResidentAvatar(idBlobNavigation, civility)} />
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={(isActive ? 'success' : 'error') || 'success'}>{isActive ? t('resident-list-active') : t('resident-list-inactive')}</Label>
                          </TableCell>

                          <TableCell align="left">{lastname}</TableCell>
                          <TableCell align="left">{firstname}</TableCell>


                          <TableCell align="left">{cabinetNo}</TableCell>
                          <TableCell align="left">{lockerNo}</TableCell>

                          <TableCell align="left">
                            {inputDate ? format(new Date(inputDate), "dd/MM/yyyy", { locale: frLocale }) : ""}
                          </TableCell>
                          <TableCell align="left">
                            {outputDate ? format(new Date(outputDate), "dd/MM/yyyy", { locale: frLocale }) : ""}
                          </TableCell>

                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" paragraph>
                            {t('user-list-not-found')}
                            </Typography>
                            <Typography variant="body2">
                              {t('resident-list-no-resident-found')}&nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> {t('user-list-verify-your-search')}
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            : <></>
          }

          <TablePagination

            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={t('user-list-result-per-page')}
            labelDisplayedRows={(page) =>
              `${page.from}-${page.to === -1 ? page.count : page.to} ${t('on-result')} ${page.count
              }`
            }
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>

    </>
  );
}
