import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams, Link, useNavigate } from 'react-router-dom';
import md5 from 'md5';
import {
  Breadcrumbs,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { LoadingButton } from '@mui/lab';
import Label from '../../components/label';
import { fetchUserByGUID, putUser } from '../../services/userService';
import Page404 from '../Page404';

export default function ResidentDetailPage() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [editedUser, setEditedUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const connectedUser = useSelector((state) => state.auth.user);
  const [passwordEdited, setPasswordEdited] = useState(0);
  const { t, i18n } = useTranslation();

  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const eightCharsOrMore= /.{8,}/g; // eight characters or more

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const data = await fetchUserByGUID(id);
        setUser(data);
        setEditedUser(data); // Initialize editedUser with user data
        setError(null);
      } catch (error) {
        setError('Error fetching user details. Please try again later.');
      }
    };
    fetchUserDetails();
  }, [id]);

  const handleInputChange = (v, key) => {
    setEditedUser({
      ...editedUser,
      [key]: v,
    });

 
    if(key === 'passwordConfirmation') {
      setPasswordEdited(1)
    }

  };

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const errors = {};

    if (!editedUser?.lastname) {
      errors.lastname = t('user-page-name-mandatory');
    }

    if (!editedUser?.firstname) {
      errors.firstname = t('user-page-first-name-mandatory');
    }

    if (!editedUser?.mailAddress) {
      errors.mailAddress = t('user-page-mail-mandatory');
    }
    if (!editedUser?.type) {
      errors.type = t('user-page-role-mandatory');
    }


    if(editedUser?.newPassword && editedUser?.newPassword !== "")
    {
      const passwordTracker = {
        uppercase: editedUser?.newPassword.match(atLeastOneUppercase),
        lowercase: editedUser?.newPassword.match(atLeastOneLowercase),
        number: editedUser?.newPassword.match(atLeastOneNumeric),
        eightCharsOrGreater: editedUser?.newPassword.match(eightCharsOrMore),
      }

      if (false && (passwordEdited && !passwordTracker.eightCharsOrGreater || !passwordTracker.uppercase || !passwordTracker.lowercase || !passwordTracker.number))
      {
        errors.newPassword = t('user-page-password-conditions')
      }
      else if(passwordEdited && editedUser.newPassword !== editedUser.passwordConfirmation)
      {
        errors.passwordConfirmation = t('user-page-password-different');
      }

    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {

      setIsLoading(true);


      if (user && passwordEdited) {
        editedUser.password = md5(editedUser.newPassword);
      }
      else if (!user) {
        editedUser.password = md5(editedUser.newPassword);
        editedUser.firstConnection = true;
        editedUser.idCustomer = connectedUser.idCustomer;
      }

      try {
        await putUser(editedUser.id, editedUser);
        navigate('/users/');
      } catch (error) {
        alert(t('user-page-error-occured'));
      } finally {
        setIsLoading(false);
      }
    } 
  };


  // Render the component structure without resident data
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Button style={{ display: 'flex', alignItems: 'center' }} component={Link} to="/users">
          <NavigateBeforeIcon style={{ marginRight: '8px' }} /> {t('user-list-users')}
        </Button>
        {user && <Typography color="textPrimary">{user.firstname} {user.lastname}</Typography>}
      </Breadcrumbs>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Card sx={{ padding: 1 }}>
            <Grid container>
              <Grid item sx={{ mt: 1, mb: 1 }}>
                <h4>{t('user-page-informations')}</h4>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <TextField
                  variant='standard'
                  label={t('user-page-name')}
                  value={editedUser?.lastname || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'lastname')}
                  fullWidth
                  error={formErrors.lastname}
                  helperText={formErrors.lastname}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant='standard'
                  label={t('user-page-first-name')}
                  value={editedUser?.firstname || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'firstname')}
                  fullWidth
                  error={formErrors.firstname}
                  helperText={formErrors.firstname}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <TextField
                  variant='standard'
                  label={t('user-page-email')}
                  value={editedUser?.mailAddress || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'mailAddress')}
                  fullWidth
                  error={formErrors.mailAddress}
                  helperText={formErrors.mailAddress}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={editedUser?.type || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'type')}
                  select
                  fullWidth
                  label={t('user-page-role')}
                  variant='standard'
                  error={formErrors.type}
                  helperText={formErrors.type}
                >
                  <MenuItem key={1} value={1}>
                    {t('user-list-administrator')}
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                  {t('user-list-user')}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={3}>
                <TextField
                  variant='standard'
                  label={t('user-page-new-password')}
                  value={editedUser?.newPassword || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'newPassword')}
                  fullWidth
                  error={formErrors.newPassword}
                  helperText={formErrors.newPassword}
                  type="password"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant='standard'
                  label={t('user-page-confirmation-password')}
                  value={editedUser?.passwordConfirmation || ''}
                  onChange={(e) => handleInputChange(e.target.value, 'passwordConfirmation')}
                  fullWidth
                  error={formErrors.passwordConfirmation}
                  helperText={formErrors.passwordConfirmation}
                  type="password"
                />
              </Grid>
              
              <Grid item xs={6} />
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Button variant='outlined' sx={{ width: '100%' }} onClick={() => { navigate('/users') }}
            startIcon={<UndoIcon />}>{t('user-page-cancel')}</Button>
        </Grid>
        <Grid item xs={3}>
          <LoadingButton loading={isLoading} variant='contained' sx={{ width: '100%' }} onClick={handleFormSubmit} endIcon={<CheckIcon />}>{t('user-page-save')}</LoadingButton>
        </Grid>
      </Grid>

    </>
  );
}
