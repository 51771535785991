import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import "./i18n"
// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey('52e48259284f2e5e75c57f4f301a20a7Tz04MTYxMixFPTE3MzYwNzA2NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

serviceWorker.unregister();

reportWebVitals();
