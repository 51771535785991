import md5 from 'md5';
import axios from 'axios';
import { DHGetKey, getAccessToken, refreshToken } from './tokenService'
import { API_URL } from './api-uri';
import { request } from './api-request';


export const loginAPI = async (email, password) => {
    const hashedPassword = md5(password);
    const response = await request('GET', 'WebUser', { email, password: hashedPassword });
    return response.data;
};

export const changePasswordAPI = async (guid, password) => {
    password = md5(password);
    const response = await request('GET', `WebUser/changePassword?guid=${guid}&password=${password}`)
    return response.data;
};

export const resetPassword = async (email) => {
    const response = await request('GET', 'WebUser/resetPassword', { email });
    return response.data;
};

