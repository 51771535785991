import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

class ModalDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const modalStyles = {
            position: 'fixed',
            top: 0,
            right: this.state.showModal ? 0 : '-100%', // Slide in from the right
            width: '70%', // Adjust the width as needed
            height: '100%', // Occupy 100% of the page height
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Gray background with transparency
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1050,
        };

        const modalContentStyles = {
            width: '100%', // Set modal content width to 100%
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '4px',
        };

        return (
            <div className="container demo">
                <div className="text-center">
                    <button
                        type="button"
                        className="btn btn-demo"
                        onClick={this.openModal}
                    >
                        Open Modal
                    </button>
                </div>

                {/* Modal */}
                {this.state.showModal && (
                    <div style={modalStyles}>
                        <div style={modalContentStyles}>
                            <Modal.Dialog>
                                <Modal.Header closeButton>
                                    <Modal.Title>Modal Title</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Modal content goes here.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.closeModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal.Dialog>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ModalDemo;
