import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';

export const sendContactMail = async (customerId, contactObjectDTO) => {
    try {
        const response = await request('POST', `Mail/SendContactMail/${customerId}`, contactObjectDTO);
        return response.data;
    } catch (error) {
        console.error('Error in sendContactMail:', error);
        throw error;
    }
};