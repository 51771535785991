import i18n from "i18next";
import { initReactI18next } from "react-i18next";


//  Importing translation files

import translationFR from "./locales/fr/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationEN from "./locales/en/translation.json";

//  Creating object with the variables of imported translation files
const resources = {
  fr: {
    translation: translationFR,
  },
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
};

//  i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"fr", //    default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  
export default i18n;