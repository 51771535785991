import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { DataGridPro } from '@mui/x-data-grid-pro';

// @mui
import {
  Card,
  Stack,
  Button,
  Popover,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';

// mock
import { fetchDeliveryList } from '../../services/deliveryService';
import CustomNoRowsOverlay from '../Resident/CustomNoRowsOverlay';

const getRowId = (row) => {
  return row.id;
}

export default function ResidentPage() {
  const [open, setOpen] = useState(null);
  const [deliveryList, setDeliveryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const { t, i18n } = useTranslation();
  const columns = [
    { field: 'status', headerName: t('delivery-list-status'), renderCell: (params) => params.rowNode.type === 'group' ? "" : params.row.status < 8 ? (<Label color={'primary'}>{t('delivery-list-in-treatment')}</Label>) : (<Label color={'success'}>{t('delivery-list-delivered')}</Label>) },
  ];

  const getTreeDataPath = (row) => {
    const currentDate = new Date(row.datetimeShipping);
    let locale;    
    if (i18n.language === 'en')
      {
        locale = 'en-US';
      }
    else if (i18n.language === 'nl')
    {
      locale = 'nl';
    }
    else
    {
      locale = 'default';
    }

    return [`${currentDate.toLocaleString(locale, { month: 'long' })} ${currentDate.getFullYear()}`, `${format(new Date(row.datetimeShipping), "P", { locale: frLocale})} - ${t('delivery-list-delivery')} n°${row.id}`]
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await fetchDeliveryList(user.idCustomer);
        data = data.filter((del) => del.datetimeShipping != null && del.status > 6)
        data.sort((a,b) => (a.datetimeInputCreation < b.datetimeInputCreation) ? 1 : ((b.datetimeInputCreation < a.datetimeInputCreation) ? -1 : 0))
        setDeliveryList(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleRowClick = (params) => {
    if(params.row.guid === undefined)
      return;

    navigate(`/deliveries/${params.row.guid}`);
  };

  const handleContactForm = () => {
    navigate('/contact-form')
  };

  return (
    <>
      <Helmet>
        <title> {t('delivery-list-delivery-historic')} </title>
      </Helmet>

      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
          {t('delivery-list-delivery-historic')}
          </Typography>
          <Button onClick={handleContactForm} sx={{ textTransform: 'none' }} variant="contained" endIcon={<Iconify icon="ic:outline-question-mark" />}>
          {t('delivery-list-specific-request')}
          </Button>
        </Stack>

        <Card>
        <DataGridPro
          rows={deliveryList || []}
          columns={columns}
          pageSize={5}
          getRowId={getRowId}
          onRowClick={handleRowClick}
          autoHeight
          treeData
          hideFooterRowCount
          disableRowSelectionOnClick
          getTreeDataPath={getTreeDataPath}
          groupingColDef={{
              headerName: t('delivery-list-delivery-date'),
              flex: 1
          }}
          components={{
              NoRowsOverlay: () => (
                  <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%">
                      <CustomNoRowsOverlay text={t('delivery-list-no-delivery')} />
                  </Box>
              ),
          }}
      />
        </Card>
      </Box>
    </>
  );
}
