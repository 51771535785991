import axios from 'axios';
import { API_URL } from './api-uri';
import { request } from './api-request';



export const fetchUserList = async (customerId) => {
    try {
        const response = await request('GET', 'WebUser', { customerId });
        return response.data;
    } catch (error) {
        console.error('Error in fetchUserList:', error);
        throw error;
    }
};

export const fetchUserByGUID = async (userGUID) => {
    try {
        const response = await request('GET', `WebUser/GUID/${userGUID}`);
        return response.data;
    } catch (error) {
        console.error('Error in fetchUserByGUID:', error);
        throw error;
    }
};

export const putUser = async (workerId, workerDTO) => {
    try {
        const response = workerId > 0 ? await request('PUT', `WebUser/${workerId}`, workerDTO) : await request('POST', `WebUser`, workerDTO);
        return response.data;
    } catch (error) {
        console.error('Error in putUser:', error);
        throw error;
    }
};