import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import md5 from 'md5';
import {
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { LoadingButton } from '@mui/lab';
import Label from '../components/label';
import { sendContactMail } from '../services/mailService';

export default function ContactForm() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const connectedUser = useSelector((state) => state.auth.user);
  const [contactObject, setContactObject] = useState([]);
  const { t, i18n } = useTranslation();
  contactObject.lastname = connectedUser?.lastname;
  contactObject.firstname = connectedUser?.firstname;
  contactObject.mailAddress = connectedUser?.mailAddress;

  const handleInputChange = (v, key) => {
    setContactObject({
      ...contactObject,
      [key]: v,
    });
  };

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const errors = {};

    if (!contactObject?.lastname) {
      errors.lastname = t('contact-page-name-mandatory');
    }

    if (!contactObject?.firstname) {
      errors.firstname = t('contact-page-first-name-mandatory');
    }

    if (!contactObject?.mailAddress) {
      errors.mailAddress = t('contact-page-mail-mandatory');
    }

    if (!contactObject?.subject) {
      errors.subject = t('contact-page-subject-mandatory');
    }
    if (!contactObject?.message) {
      errors.message = t('contact-page-message-mandatory');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {

      setIsLoading(true);
      try {
        await sendContactMail(connectedUser ? connectedUser.idCustomer : 551, contactObject);
        toast.success(t('contact-page-message-sent'))
      } catch (error) {
        toast.error(t('contact-page-error'))
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Grid container alignItems="center">
            <Grid item sx={{ mt: 1, mb: 1 }}>
              <h4>{t('contact-page-form')}</h4>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                variant='standard'
                label={t('contact-page-name')}
                value={contactObject?.lastname || ''}
                onChange={(e) => handleInputChange(e.target.value, 'lastname')}
                fullWidth
                error={formErrors.lastname}
                helperText={formErrors.lastname}
                autoFocus={!connectedUser}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant='standard'
                label={t('contact-page-first-name')}
                value={contactObject?.firstname || ''}
                onChange={(e) => handleInputChange(e.target.value, 'firstname')}
                fullWidth
                error={formErrors.firstname}
                helperText={formErrors.firstname}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <TextField
                variant='standard'
                label={t('contact-page-email')}
                value={contactObject?.mailAddress || ''}
                onChange={(e) => handleInputChange(e.target.value, 'mailAddress')}
                fullWidth
                error={formErrors.mailAddress}
                helperText={formErrors.mailAddress}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant='standard'
                label={t('contact-page-phone')}
                value={contactObject?.phoneNumber || ''}
                onChange={(e) => handleInputChange(e.target.value, 'phoneNumber')}
                fullWidth
                error={formErrors.phoneNumber}
                helperText={formErrors.phoneNumber}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                variant='standard'
                label={t('contact-page-subject')}
                value={contactObject?.subject || ''}
                onChange={(e) => handleInputChange(e.target.value, 'subject')}
                fullWidth
                autoFocus={connectedUser}
                error={formErrors.subject}
                helperText={formErrors.subject}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                variant='standard'
                label={t('contact-page-message')}
                value={contactObject?.message || ''}
                onChange={(e) => handleInputChange(e.target.value, 'message')}
                fullWidth
                multiline
                rows={8}
                maxRows={12}
                error={formErrors.message}
                helperText={formErrors.message}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <Button variant='outlined' sx={{ width: '100%' }} onClick={() => { navigate('/') }}
                startIcon={<UndoIcon />}>{t('contact-page-cancel')}</Button>
            </Grid>
            <Grid item xs={3}>
              <LoadingButton loading={isLoading} variant='contained' sx={{ width: '100%' }} onClick={handleFormSubmit} endIcon={<CheckIcon />}>{t('contact-page-send')}</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
