import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import SvgColor from '../../../components/svg-color';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import CountrySelect from '../../../components/CountrySelect';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.auth.user);
  const isDesktop = useResponsive('up', 'lg');
  const [country, setCountry] = useState(localStorage.getItem('language') != null ? localStorage.getItem('language') : "fr");
  const { t, i18n } = useTranslation()
  
  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const navConfig = [
    {
      title: t('nav-dashboard'),
      path: '/',
      admin: 1,
      icon: icon('ic_analytics'),
    },
    {
      title: t('nav-resident'),
      path: '/residents',
      admin: 0,
      icon: icon('ic_user'),
    },
    {
      title: t('nav-delivery'),
      path: '/deliveries',
      admin: 1,
      icon: <LocalShippingIcon />,
    },
    {
      title: t('nav-invoicing'),
      path: '/invoices',
      admin: 1,
      icon: <DescriptionIcon />,
    },
    {
      title: t('nav-user'),
      path: '/users',
      admin: 1,
      icon: <PeopleIcon />,
    }
  ];

  useEffect(() => {
    if (openNav) {
      onCloseNav();
      handleLanguageChange(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLanguageChange = (newValue) => {
    setCountry(newValue);
    i18n.changeLanguage(newValue.toLowerCase())
    localStorage.setItem('language', newValue);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo sx={{ mx: 'auto', width: 120, height: 100 }} />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <AccountCircleRoundedIcon color="primary" fontSize="large" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`${user.firstname} ${user.lastname}`}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 11 }}>
                {user.mailAddress}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />


    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
