import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close'
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import { Card, CircularProgress, Grid, Typography } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import { UpsertContactPerson, deleteContactPerson } from '../../services/contactPersonService';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';

export default function ResidentContacts({ resident, readonly }) {

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const { t, i18n } = useTranslation();
  const roles = [t('resident-page-family'), t('resident-page-administrator')];

  useEffect(() => {
    if (resident && resident.contacts) {
      setRows(resident.contacts);
    }
  }, [resident]);

  function EditToolbar(props) {
    const { setRows, setRowModesModel, resident } = props;
  
    const handleClick = () => {
  
      if (!resident || !resident?.id) {
        toast.error(t('resident-page-save-first'));
        return;
      }
  
  
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        { identifiant: id, nom: '', prenom: '', telephone: '', adresseMail: '', role: roles[1], isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nom' },
      }));
    };
  
    return (
      <Grid container justifyContent="flex-end">
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} variant='contained'>
          {t('resident-page-add')}
        </Button>
      </Grid>
    );
  }

  const handleEditClick = (identifiant) => () => {
    setRowModesModel({ ...rowModesModel, [identifiant]: { mode: 'edit' } });
  };

  const handleSaveClick = (identifiant) => async () => {
    setRowModesModel({ ...rowModesModel, [identifiant]: { mode: 'view' } });
  };

  const handleDeleteClick = (identifiant) => async () => {
    try {
      await deleteContactPerson(identifiant);
      const updatedRows = rows.filter((row) => row.identifiant !== identifiant);
      setRows(updatedRows);
    } catch (error) {
      console.error(`Error deleting contact person: ${error.message}`);
    }
  };

  const handleCancelClick = (identifiant) => () => {
    setRowModesModel({
      ...rowModesModel,
      [identifiant]: { mode: 'view', ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.identifiant === identifiant);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.identifiant !== identifiant));
    }
  };

  const processRowUpdate = async (newRow) => {

    const updatedRow = { ...newRow };

    try {
      if (updatedRow.isNew) {
        updatedRow.identifiant = 0;
        updatedRow.IdWorker = resident.id
        const newContactPerson = await UpsertContactPerson(0, updatedRow);
        updatedRow.identifiant = newContactPerson.identifiant;
      } else {
        await UpsertContactPerson(updatedRow.identifiant, updatedRow);
      }
    } catch (error) {
      console.error(`Error saving contact person: ${error.message}`);
    }

    const updatedRows = rows.map((row) => row.identifiant === newRow.identifiant ? updatedRow : row);
    setRows(updatedRows);
    return updatedRow;
  };

  const columns = [

    {
      field: 'nom', headerName: t('resident-page-name'), width: 200, editable: true, valueParser: (value, params) => { return value.length > 40 ? value.substring(0, 40) : value },
    },
    {
      field: 'prenom', headerName: t('resident-page-first-name'), width: 200, editable: true, valueParser: (value, params) => { return value.length > 40 ? value.substring(0, 40) : value }
    },
    {
      field: 'telephone', headerName: t('resident-page-phone'), width: 200, editable: true, valueParser: (value, params) => { return value.length > 10 ? value.substring(0, 10) : value }
    },
    {
      field: 'adresseMail', headerName: t('resident-page-email'), width: 200, editable: true, valueParser: (value, params) => { return value.length > 100 ? value.substring(0, 100) : value },
    },
    {
      field: 'description',
      headerName: t('resident-page-role'),
      editable: true,
      width: 150,
      type: 'singleSelect',
      valueOptions: roles,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === 'edit';

        if (readonly) {
          return []
        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Card sx={{ height: '100%' }}>
      <Grid container p={2}>
        <Grid item xs={6}>
          <Typography variant='h4'>{t('resident-page-contact-person')}</Typography>
        </Grid>
        {
          !readonly &&
          <Grid item xs={6}>
            <EditToolbar setRows={setRows} setRowModesModel={setRowModesModel} resident={resident} />
          </Grid>
        }
      </Grid>
      <Box sx={{ height: '100%' }}>
        {resident ? (
          rows && rows.length > 0 ? (
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              getRowId={(row) => row.identifiant}
              onRowEditStop={processRowUpdate}
              processRowUpdate={processRowUpdate}
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CustomNoRowsOverlay text={t('resident-page-no-contact-person')} />
            </Box>
          )
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

    </Card>
  );
}
