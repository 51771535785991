// YesNo.js
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function YesNo({ setResult, open, setOpen, message }) {
    const handleClose = (result) => {
        setOpen(false);
        setResult(result);
    };

    return (
        <Dialog open={open} onClose={() => handleClose(0)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(0)} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => handleClose(1)} autoFocus color="primary">
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
