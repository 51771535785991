import React from 'react';
import { useTranslation } from "react-i18next";

import { Card, CardContent, Grid, Typography } from '@mui/material';
import CountUp from 'react-countup';


export default function DidYouKnow({ data }) {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Typography mb={1}>📊{t('did-you-know-some-numbers')} </Typography>
            <Card sx={{ minHeight: '225px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Grid container justifyContent='center' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            {t('did-you-know-text')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h2'>
                            {data && <CountUp end={data.totalScanSessionThisMonth} separator="." duration={1} />}
                            👕
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body6">
                            {t('did-you-know-cleaned-clothes-month')}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>

        </>
    );
}
