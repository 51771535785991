import { useState, React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Typography,
    Card
} from '@mui/material';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Avatar from '@mui/material/Avatar';
import toast, { Toaster } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import Label from '../../components/label';
import ConfirmDialog from '../../components/confirm/ConfirmDialog';
import { UpsertWorker } from '../../services/workerService';

export default function ResidentInformations({ resident, setResident, handleInputChange, setEdited, readonly }) {

    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [newBlob, setNewBlob] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const connectedUser = useSelector((state) => state.auth.user);
    const { t, i18n } = useTranslation();

    const validateForm = () => {
        const errors = {};

        if (!resident?.lastname) {
            errors.lastname = t('resident-page-name-mandatory');
        }

        if (!resident?.firstname) {
            errors.firstname = t('resident-page-first-name-mandatory');
        }

        if (!resident?.civility) {
            errors.civility = t('resident-page-sex-mandatory');
        }

        if (!resident?.cabinetNo) {
            errors.cabinetNo = t('resident-page-floor-mandatory');
        }

        if (!resident?.lockerNo) {
            errors.lockerNo = t('resident-page-room-mandatory');
        }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async () => {
        try {
            setIsLoading(true);
    
            const isFormValid = validateForm();
            if (!isFormValid) return;

            const updatedResident = await updateResident(resident);
            
            if (!resident.id) {
                updatedResident.webClothes = [];
                setResident(updatedResident);
            }
            
            toast.success(t('resident-page-saved'));

        } catch (error) {
            const errorCode = error.response?.data?.errorCode;
            const errorMessage = errorCode ? t(`resident-page-error-${errorCode}`) : t('resident-page-error');
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
            setEdited(false);
        }
    };
    
    const updateResident = async (resident) => {
          
        resident.DatetimeUpdate = new Date();

        if (!resident.id) {
            resident.idCustomer = connectedUser.idCustomer;
            resident.DatetimeCreation = new Date();
            if (!resident.inputDate) {
                resident.inputDate = new Date();
            }
            if (connectedUser.customer != null) {
                resident.directInvoicing = connectedUser.customer.directInvoicing ?? false;
                resident.idCompany = connectedUser.customer.idCompany;
            }
        }

        const updatedResident = await UpsertWorker(resident.id, resident);
        return updatedResident;
    };

    const handleOutputDateChange = (e) => {
        resident.isActive = e.target.value > formatDateObject(new Date())
        handleInputChange(e.target.value, 'outputDate')
    }

    function formatDateObject(currentDate) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    function formatDateString(initialDate) {
        return initialDate ? initialDate.toString().split('T')[0] : null;
    }

    function handleActiveCheck(e) {
        resident.outputDate = e.target.checked ? null : formatDateObject(new Date())
        handleInputChange(e.target.checked, 'isActive')
    }

    function handleAliveCheck(e) {

        if (e.target.checked) {
            resident.isActive = 0
            resident.outputDate = formatDateObject(new Date())
        }

        handleInputChange(!e.target.checked, 'isAlive')
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleImageChange = async (event) => {
        const file = event.target.files[0]
        if (file) {
            resident.NewBlob = await toBase64(file);
            resident.idBlobNavigation = null;
            resident.idBlob = null;
            setNewBlob(true);
        }
    };

    const handleImageRemove = () => {
        resident.NewBlob = null;
        resident.idBlobNavigation = null;
        resident.idBlob = null;
        setNewBlob(false);
        setConfirmOpen(false);
    };

    return (
        <Card sx={{ padding: 2 }}>
            <Grid item xs={12} sx={{ mb: 1 }}>
                <Grid container alignItems="center">
                    <Grid item sx={{ mt: 1, mb: 1 }}>
                        <Typography variant='h4'>{t('resident-page-informations')}</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                        <FormControlLabel
                            disabled={readonly}
                            checked={resident?.isActive || !resident}
                            onChange={(e) => handleActiveCheck(e)}
                            control={<Switch />}
                            label={<Label color={resident?.isActive || !resident ? 'success' : 'error'}>{resident?.isActive || !resident ? t('resident-page-active') : t('resident-page-inactive')}</Label>}
                        />

                        {resident &&
                            <FormControlLabel
                                disabled={readonly}
                                checked={resident.isAlive === false}
                                onChange={(e) => handleAliveCheck(e)}
                                control={<Switch />}
                                label={<Label>✝️{t('resident-page-dead')} </Label>}
                            />
                        }

                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent={'center'}>
                    {resident && ((resident.idBlob != null && resident.idBlobNavigation != null) || resident.NewBlob != null) ?
                        <>
                            <Grid item xs={2}>
                                <Avatar sx={{ height: "50px", width: "50px" }} onClick={() => setConfirmOpen(true)}>
                                    <img alt="Résident" src={resident.NewBlob ? resident.NewBlob : resident.idBlobNavigation.path + resident.idBlobNavigation.name} />
                                </Avatar>
                                <ConfirmDialog
                                    title={t('resident-page-delete-picture')}
                                    open={confirmOpen}
                                    setOpen={setConfirmOpen}
                                    onConfirm={handleImageRemove}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            {!readonly &&
                                <Grid item xs={2}>
                                    <IconButton variant="contained" component="label">
                                        <Avatar sx={{ height: "50px", width: "50px" }}>
                                            <AddAPhotoIcon />
                                            <input type="file" hidden accept=".png,.gif,.jpg,.jpeg" max={3 * 1024 * 1024} onChange={handleImageChange} />
                                        </Avatar>
                                    </IconButton >
                                </Grid>}
                        </>
                    }

                    <Grid item xs={5}>
                        {resident &&
                            <TextField
                                variant='standard'
                                label={t('resident-page-name')}
                                value={resident?.lastname || ''}
                                onChange={(e) => handleInputChange(e.target.value, 'lastname')}
                                fullWidth
                                error={formErrors.lastname}
                                helperText={formErrors.lastname}
                                autoFocus={!resident.id > 0}
                                disabled={readonly}
                                inputProps={{ maxLength: 50 }}
                            />
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            variant='standard'
                            label={t('resident-page-first-name')}
                            value={resident?.firstname || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'firstname')}
                            fullWidth
                            disabled={readonly}
                            error={formErrors.firstname}
                            helperText={formErrors.firstname}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <TextField
                            value={resident?.civility || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'civility')}
                            select
                            disabled={readonly}
                            fullWidth
                            label={t('resident-page-sex')}
                            variant='standard'
                            error={formErrors.civility}
                            helperText={formErrors.civility}
                        >
                            <MenuItem key={1} value={1}>
                                {t('resident-page-sex-male')}
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                {t('resident-page-sex-female')}
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label={t('resident-page-birth-date')}
                            value={formatDateString(resident?.birthDate) || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'birthDate')}
                            type='date'
                            disabled={readonly}
                            variant='standard'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <TextField
                            label={t('resident-page-floor')}
                            variant='standard'
                            disabled={readonly}
                            value={resident?.cabinetNo || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'cabinetNo')}
                            fullWidth
                            error={formErrors.cabinetNo}
                            helperText={formErrors.cabinetNo}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label={t('resident-page-room')}
                            variant='standard'
                            value={resident?.lockerNo || ''}
                            onChange={(e) => handleInputChange(e.target.value, 'lockerNo')}
                            fullWidth
                            disabled={readonly}
                            error={formErrors.lockerNo}
                            helperText={formErrors.lockerNo}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={5}>
                        <TextField
                            label={t('resident-page-date-creation')}
                            variant='standard'
                            value={formatDateString(resident?.inputDate) || formatDateObject(new Date())}
                            type='date'
                            disabled={readonly}
                            onChange={(e) => handleInputChange(e.target.value, 'inputDate')}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label={t('resident-page-date-end')}
                            variant='standard'
                            value={formatDateString(resident?.outputDate) || ''}
                            type='date'
                            disabled={readonly}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => handleOutputDateChange(e)}
                            fullWidth
                        />
                    </Grid>
                    {!readonly && (
                        <>
                            <Grid item xs={2} />
                            <Grid item xs={5}>
                                <Button variant="outlined" sx={{ width: '100%' }} onClick={() => navigate('/residents/')}>
                                    {t('resident-page-cancel')}
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <LoadingButton loading={isLoading} variant="contained" sx={{ width: '100%' }} onClick={handleFormSubmit} endIcon={<CheckIcon />}>
                                    {t('resident-page-save')}
                                </LoadingButton>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Grid>
        </Card >
    );
}
