import axios from 'axios';
import CryptoJS from 'crypto-js';
import { API_URL } from "./api-uri";


export const DHGetKey = async (dhkey) => {
  const response = await axios.get(`${API_URL}Authentications/DHGetKeyBase64/${stringToHex(dhkey)}`);
  return response.data;
};


export const getAccessToken = async () => {
  try {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      return storedToken;
    }

    const dhKey = await DHGetKey('AA');

    const tokenStructure = getTokenStructure(dhKey.authenticationId);
    const response = await axios.put(`${API_URL}Authentications/GetOAuthTokenBase64`, JSON.stringify(tokenStructure), {
      headers: { 'Content-Type': 'application/json' },
    });

    const newToken = response.data;

    localStorage.setItem('token', newToken);

    return newToken;
  } catch (error) {
    console.error('Error in getAccessToken:', error);
    throw error;
  }
};

export const refreshToken = async (dh) => {
  try {
    localStorage.removeItem('token');
    return await getAccessToken(dh);
  } catch (error) {
    console.error('Error in refreshToken:', error);
    throw error;
  }
};


const TestToken = (token) => {
  return axios
    .get(`${API_URL}Authentications/TestToken`)
    .then((response) => {
      if (response.data.id) {
        localStorage.setItem("bearer", response.data);
      }
      return response.data;
    });
};

const stringToHex = (str) => {
  let hex = '';
  for (let i = 0; i < str.length; i += 1) {
    const charCode = str.charCodeAt(i);
    const hexValue = charCode.toString(16);
    hex += hexValue.padStart(2, '0');
  }
  return hex;
};



const getEncryptedBuffer = () => {
  const netPw = "&0lt4L@mFlmGxB2GF*MZu2PD6zYkUtSgka@cdg%6cvdaZOnNu7";
  const key = "61133720129872260000000000000000";
  const iv = "0000000000000000";

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const enc = CryptoJS.AES.encrypt(netPw, fkey, {
    iv: fiv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return enc.ciphertext.toString(CryptoJS.enc.Base64);
}

const getTokenStructure = (dhid) => {
  const encbuff = getEncryptedBuffer();
  return {
    encryptedPassword: encbuff.replaceAll("/", "!").replaceAll("+", "_"),
    encryptionIV: "0000000000000000",
    authenticationId: dhid,
  };
};

