import React from 'react';
import { useTranslation } from "react-i18next";

import { Card, CardContent, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

export default function NextPickUp({ data }) {
    const { t, i18n } = useTranslation();
    const getDelay = (date) => {
        const date1 = new Date(date);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    return (
        <>
            <Typography mb={1}>👖 {t('next-pickup-title')}</Typography>
            <Card sx={{ minHeight: '225px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Grid container justifyContent='center' spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('next-pickup-planified')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            data &&
                            <Typography variant='h2'>
                                {`🗓️ ${getDelay(data.nextPickUpDate)} ${getDelay(data.nextPickUpDate) === 1 ? t('next-pickup-day') : t('next-pickup-days')}`}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {data &&
                            <Typography variant="body6">
                                {t('next-pickup-dont-forget')} <br />
                                <span style={{ fontWeight: 'bold' }}>
                                    {format(new Date(data.nextPickUpDate), "dd/MM/yyyy", { locale: frLocale })}
                                </span>
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}
