import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Annoncement() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <>
            <Typography mb={1}>💡{t('announcement-title')}</Typography>
            <Card sx={{ minHeight: '225px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            {t('announcement-specific-request')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {t('announcement-description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button sx={{ backgroundColor: 'black', textTransform: 'none' }} onClick={() => { navigate('/contact-form') }} variant='contained'>{t('announcement-fill-request')}</Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}
