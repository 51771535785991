
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Grid } from '@mui/material';
// hooks
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import useResponsive from '../hooks/useResponsive';

// components
import Logo from '../components/logo';
import SetPassword from '../sections/auth/login/SetPassword';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import ContactForm from './ContactForm';
import Header from '../layouts/dashboard/header'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage({ setPassword }) {
  const mdUp = useResponsive('up', 'md');
  const [contact, setContact] = useState(false);
  const { t, i18n } = useTranslation();

  const handleContactClick = () => {
    setContact(true);
  };

  return (
    <>
      <Header />

      <Helmet>
        <title> Login | BeeClean </title>
      </Helmet>

      {!contact &&
        <Container maxWidth="sm">

          <Logo sx={{ mx: 'auto', my: 10 }} />

          {!setPassword &&
            <>
              <Typography variant="h4" gutterBottom>
                {t('login-connect-to-app')}
              </Typography>

              <Typography variant="body2" sx={{ mb: 5 }}>
                {t('login-no-account-and-interested')}
                <Button sx={{ textTransform: 'none' }} onClick={handleContactClick}>{t('login-contact-us')}</Button>
              </Typography>



            </>
          }

          {setPassword ? <SetPassword /> : <LoginForm />}
        </Container>
      }
      {contact &&
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Logo sx={{ mx: 'auto', my: 10 }} />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8} alignItems="center" justifyContent="center">
              <ContactForm />
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}
