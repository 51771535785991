import { React, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import ConfettiExplosion from 'react-confetti-explosion';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import { Box, InputLabel, Typography } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../components/logo';
import { getCustomerInformation, postCollect } from '../../services/collectService';
import YesNo from '../YesNo';

dayjs.extend(customParseFormat);
dayjs.locale('fr');

function CollectCancel() {

    const { guid } = useParams();
    const [customerData, setCustomerData] = useState();
    const [cancelSent, setCancelSent] = useState();

    useEffect(() => {
        fetchData();
    }, [guid]);

    const fetchData = async () => {
        try {
            const customerData = await getCustomerInformation(guid);
            setCustomerData(customerData)
        } catch (error) {
            toast.error('Une erreur est survenue lors de la récupération des informations liées à la demande de collecte');
        }
    };

    const validateForm = async () => {

    }

    return (
        <Container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', direction: 'column', height: '100%', backgroundColor: '#18407F' }}>
            aaaa
        </Container >
    );
}

export default CollectCancel;
