import React from "react";
import {
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogActions>
      <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          sx={{ textAlign:"center" }}
        >
          Oui
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          sx={{ textAlign:"center" }}
        >
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;