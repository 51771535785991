import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Alert, // Add this import for displaying error messages
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { login } from '../../../actions/authActions';
import CountrySelect from '../../../components/CountrySelect';
import Iconify from '../../../components/iconify';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState("FR");

  // Add error state and error message
  const [error, setError] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("language", country)) {
      const countryCode = localStorage.getItem("language", country.toLowerCase()); 
      handleLanguageChange(countryCode);
    }
  }, []);

  const handleLanguageChange = (newValue) => {
    setCountry(newValue);
    i18n.changeLanguage(newValue.toLowerCase())
    localStorage.setItem('language', newValue.toLowerCase());
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleForgetPasswordClick = () => {
    navigate('/forget-password')
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      await dispatch(login(email, password));
    } catch (error) {
      setError(t('login-error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label={t('login-mail-address')}
            value={email}
            onChange={onChangeEmail}
          />

          <TextField
            name="password"
            label={t('login-password')}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={onChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {error && <Alert sx={{ m: 2 }} severity="error">{error}</Alert>}
        
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Button sx={{ textTransform: 'none' }} onClick={handleForgetPasswordClick}>{t('login-forgot-password')}</Button>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          disabled={!password || !email}
          type="submit"
          variant="contained"
          loading={isLoading}
          sx={{ textTransform: 'none' }}>
          {t('login-connect')}
        </LoadingButton>
      </form>
    </>
  );
}
