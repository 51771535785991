import React, { useEffect, useState } from "react";
import { Grid, Typography, Badge, Button } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import dayjs from "dayjs";
import { request } from '../../services/api-request';
import './Calendar.css'


export default function AdminCalendar() {

  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState(dayjs(new Date()));
  const [events, setEvents] = useState();
  const [nbrTodayCollection, setNbrTodayCollection] = useState(0);
  const [nbrTodayDelivery, setNbrTodayDelivery] = useState(0);

  useEffect(() => {
    getToday();
  }, []);

  const onDateSelect = (selectedDay) => {
    if (!selectedDay) return;

    const day = selectedDay.$D.toString().padStart(2, "0");
    const month = (selectedDay.month() + 1).toString().padStart(2, "0");
    const year = selectedDay.year();
    const date = `${day}${month}${year}`;
    navigate(`/calendar/${date}`);
  }

  useEffect(() => {
    if (currentMonth) {
      const fetchData = async () => {
        try {

          const e1 = await request('GET', `CustomerCollectRequest/all/${currentMonth.year()}/${currentMonth.month() + 1}`);

          const today = new Date().toISOString().substring(0, 10);


          const nbrDeliveryToday = e1.data.filter((e) => e.plannedDeliveryDate ? e.plannedDeliveryDate.substring(0, 10) === today : false).length;
          const nbrCollectionToday = e1.data.filter((e) => e.plannedCollectionDate ? e.plannedCollectionDate.substring(0, 10) === today : false).length;

          setEvents(e1.data);
          setNbrTodayDelivery(nbrDeliveryToday);
          setNbrTodayCollection(nbrCollectionToday);

        } catch (error) {
          toast.error('Une erreur est survenue lors de la récupération des collectes');
        }
      };

      fetchData();
    }
  }, [currentMonth]);


  const getToday = () => {
    setCurrentMonth(dayjs(new Date()));
  }

  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;

    const checkEvents = (eventList, field) => eventList.some((e) => {
      if (e[field]) {
        const eventDate = new Date(e[field]).toISOString().substring(0, 10);
        const targetDate = day.toISOString().substring(0, 10);
        return eventDate === targetDate;
      }
      return false;
    });

    let badgeContent = '';
    if (checkEvents(events, 'plannedCollectionDate')) { badgeContent += '🟢'; }
    if (checkEvents(events, 'plannedDeliveryDate')) { badgeContent += '🔵'; }
    if (outsideCurrentMonth) { badgeContent = '' }

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={badgeContent}>
        <PickersDay {...other} day={day} outsideCurrentMonth={outsideCurrentMonth} />
      </Badge>
    );
  }

  return (
    <Grid container style={{ backgroundColor: "#0A1F41" }}>
      <Grid item xs={12} sx={{ color: "white", height: '18vh' }}>
        <Grid container spacing={3} pl={3} pt={4}>
          <Grid item container>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Typography variant="h4" fontFamily={'Poppins'}>
                  {"Quoi de prévu"}
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={getToday} sx={{ textTransform: 'none' }}>
                  <Typography variant="h4" fontFamily={'Poppins'} color={'white'}>
                    {"aujourd'hui ?"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} pl={3} pb={3}>
          <Grid item>
            <Typography variant="h3" fontFamily={'Poppins'}>{nbrTodayCollection}</Typography>
            <Typography fontSize={10} fontFamily="Poppins" sx={{ color: '#cbcbcb' }}>
              {`collecte${nbrTodayCollection > 1 ? 's' : ''}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" fontFamily={'Poppins'}>{nbrTodayDelivery}</Typography>
            <Typography fontSize={10} fontFamily="Poppins" sx={{ color: '#cbcbcb' }}>
              {`livraison${nbrTodayDelivery > 1 ? 's' : ''}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: "#E9E9E9", height: '80vh', borderTopLeftRadius: '40px', borderTopRightRadius: '40px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {currentMonth ?
            <>
              <DateCalendar
                value={currentMonth}
                onChange={(e) => { onDateSelect(e); }}
                views={['day']}
                slots={{ day: events ? ServerDay : null }}
                onMonthChange={(m) => {
                  if(m.month() === dayjs(new Date()).month()) {
                    setCurrentMonth(dayjs(new Date()))
                  } else {
                    setCurrentMonth(m)
                  }
                }}
                
  
              />
            </>
            :
            <>
              <DayCalendarSkeleton />
            </>
          }
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
