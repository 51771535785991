import { React, useEffect, useState, createRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ContentCopy, CopyAll, Copyright, Delete, DeleteForever } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import { Box, ButtonGroup, IconButton, InputAdornment, InputLabel, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { blue, green } from '@mui/material/colors';
import SignatureCanvas from 'react-signature-canvas'
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import Logo from '../../components/logo';
import { getCollect, getCustomerInformation, postCollect, putCollect } from '../../services/collectService';
import YesNo from '../YesNo';

dayjs.extend(customParseFormat);
dayjs.locale('fr');

function CollectDeliveryValidation() {

    const { id } = useParams();
    const [data, setData] = useState();

    const [isValidated, setIsValidated] = useState();
    const [imageError, setImageError] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    let sigPad = {};

    useEffect(() => {
        fetchData();
    }, [id]);

    const getShortDate = (date) => {
        const day = date.$D.toString().padStart(2, "0");
        const month = (date.month() + 1).toString().padStart(2, "0");
        const year = date.year();
        return `${day}${month}${year}`;
    }

    const backToEvents = () => {
        if (!data) { return; }
        navigate(`/calendar/${getShortDate(dayjs(isCollect(data) ? data.plannedCollectionDate : data.plannedDeliveryDate))}`)
    }

    const isCollect = (event) => {
        return event.plannedDeliveryDate == null
    }

    const fetchData = async () => {
        try {
            const data = await getCollect(id);
            setIsValidated(data.effectiveCollectionDate || data.effectiveDeliveryDate)
            setData(data)
        } catch (error) {
            toast.error('Une erreur est survenue lors de la récupération des informations liées à la collecte/livraison');
        }
    };

    const handleValidate = () => {
        toast.promise(
            putCollect(data),
            {
                loading: 'Validation en cours...',
                success: () => {
                    backToEvents();
                    return <><b>{`${isCollect(data) ? 'Collecte' : 'Livraison'} enregistrée avec succès !`}</b></>;
                },
                error: <b>{`Une erreur est survenue lors de la validation de la ${isCollect(data) ? 'collecte' : 'livraison'}.`}</b>,
            }
        );
    }

    const getSignature = () => {
        const isLocalhost = location.pathname === "localhost";
        const isTestEnvironment = location.pathname.includes('test');

        const baseUrl = isLocalhost || isTestEnvironment
            ? "https://netbee.blob.core.windows.net/cleaning-management/cleaning-management/B2C/signatures"
            : "https://netbee.blob.core.windows.net/debugtests/cleaning-management/B2C/signatures";

        return `${baseUrl}/${data.id}/signature.png`;
    };


    const handleIncrement = () => {
        setData((prevData) => ({
            ...prevData,
            quantity: prevData.quantity + 1,
        }));
    };

    const handleDecrement = () => {
        if (data.quantity > 0) {
            setData((prevData) => ({
                ...prevData,
                quantity: prevData.quantity - 1,
            }));
        }
    };

    const handleNameChange = (name) => {
        setData((prevData) => ({
            ...prevData,
            signatoryName: name
        }));
    };

    const endSignature = () => {
        const bytes = []
        const dataURL = sigPad.toDataURL();
        const base64Data = dataURL.split(',')[1];
        const binaryString = atob(base64Data);

        for (let i = 0; i < binaryString.length; i += 1) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        setData((prevData) => ({ ...prevData, SignatureBytes: bytes }));
    }

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <Grid container style={{ backgroundColor: "#0A1F41", fontFamily: "Poppins" }}>
            <Grid item xs={12} sx={{ color: "white" }}>
                <Grid container spacing={3} p={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12}>
                            <Grid item>
                                <Button onClick={() => backToEvents()} sx={{ color: "white" }}>
                                    <ArrowBackIcon />
                                </Button>
                            </Grid>
                            <Grid item >
                                {
                                    data &&
                                    <Typography variant="h4" fontFamily={"Poppins"}>
                                        {isCollect(data) ? 'Collecte ' : 'Livraison '} {isValidated ? '✅' : ''}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Coordonnées
                        </InputLabel>
                        <TextField
                            sx={{ input: { color: 'white' } }}
                            fullWidth
                            value={data ? (data.customerCompanyName ? data.customerCompanyName : `${data.customerLastName} ${data.customerFirstName}`) : ''}
                            variant="standard"
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Adresse de livraison
                        </InputLabel>
                        <TextField
                            sx={{ input: { color: 'white' } }}
                            fullWidth
                            variant="standard"
                            InputProps={{ readOnly: true }}
                            value={data ? data.customerAddress : ''}
                        />
                    </Grid>
                    {data && data.customerPhone ?
                        <Grid item xs={12}>
                            <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                                Contact
                            </InputLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type='phone'
                                value={data ? data.customerPhone : ''}
                                disabled
                                InputProps={{
                                    style: { backgroundColor: 'white', borderRadius: '1px' },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {
                                                toast.success('Numéro de téléphone copié !', {
                                                    position: 'top-center'
                                                });
                                                navigator.clipboard.writeText(data ? data.customerPhone : '')
                                            }}>
                                                <ContentCopy />
                                            </IconButton>
                                        </InputAdornment>
                                }} />
                        </Grid>
                        :
                        <></>
                    }

                    <Grid item xs={12}>
                        <InputLabel sx={{ color: 'white', fontWeight: 'bold' }}>
                            Détail de la livraison
                        </InputLabel>
                        <ButtonGroup size="large" disabled={isValidated}>
                            <Button onClick={handleDecrement}>-</Button>
                            <Button
                                sx={{ backgroundColor: '#FFFFFF', color: 'black', borderRadius: 15 }}
                                disabled>
                                {data && data.quantity ? data.quantity : 0}
                            </Button>
                            <Button onClick={handleIncrement}>+</Button>
                        </ButtonGroup>
                        {` Sac${data && data.quantity > 1 ? 's' : ''}`}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "#E9E9E9", borderTopLeftRadius: "40px", borderTopRightRadius: "40px" }}>
                <Grid container justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} ml={2} mt={1}>
                        {
                            data &&
                            <Typography fontWeight={'bold'} p={1}>
                                Pour valider la{isCollect(data) ? ' demande de collecte' : ' livraison'}, veuillez signer ci-dessous 
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} backgroundColor='#FFFFFF' p={1} borderRadius={3} m={2} mb={1}>
                        <InputLabel sx={{ color: 'black', fontWeight: 'bold' }}>
                            Nom :
                        </InputLabel>
                        <TextField
                            fullWidth
                            value={data ? data.signatoryName : ''}
                            onChange={(e) => handleNameChange(e.target.value)}
                            variant="outlined"
                            disabled={isValidated}
                            InputProps={{ style: { backgroundColor: 'white', borderRadius: '1px' } }}
                        />
                    </Grid>
                    <Grid item xs={12} backgroundColor='#FFFFFF' p={1} borderRadius={3} m={2} mt={0}>

                        {
                            isValidated ?
                                (imageError ? (
                                    <div>Validated without any signature</div>
                                ) : (
                                    <img
                                        src={getSignature()}
                                        alt='signature'
                                        onError={handleImageError}
                                    />
                                )) :
                                (
                                    <SignatureCanvas
                                        penColor='black'
                                        ref={(ref) => { sigPad = ref }}
                                        canvasProps={{
                                            height: 200,
                                            width: 1000
                                        }}
                                        onEnd={() => endSignature()}
                                    />
                                )
                        }

                    </Grid>

                    {!isValidated &&
                        <Grid container m={1}>
                            <Grid item xs={10}>
                                <Button variant="contained" style={{ backgroundColor: '#06C694', width: '100%', borderRadius: '10px' }} onClick={handleValidate} >
                                    Valider
                                </Button>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <IconButton onClick={() => {
                                    setData((prevData) => ({ ...prevData, SignatureBytes: [] }));
                                    sigPad.clear();
                                }}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CollectDeliveryValidation;
