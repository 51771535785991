import { useState } from 'react';
import { useTranslation } from "react-i18next";

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { logout } from '../../../actions/authActions';
// mocks_
import account from '../../../_mock/account';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Accueil',
    icon: 'eva:home-fill',
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {

  const [open, setOpen] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (index) => {
    switch (index) {
      case 1:
        navigate('/')
        break;
      case 4:
        dispatch(logout())
        break;
      default:
        break;
    }
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <SettingsRoundedIcon color="primary" fontSize="medium" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        {user &&
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user.mailAddress}
            </Typography>
          </Box>}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem key={"Accueil"} onClick={() => { handleClose(1) }}>
            {t('account-menu-home')}
          </MenuItem>
        </Stack>

        <MenuItem onClick={() => { handleClose(4) }} sx={{ m: 1 }}>
          {t('account-menu-disconnect')}
        </MenuItem>
      </Popover>
    </>
  );
}
