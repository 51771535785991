import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, CircularProgress, Box, Card, CardContent, Avatar, ButtonGroup } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";
import { styled, createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { blue, green } from '@mui/material/colors';
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { request } from '../../services/api-request';
import { CustomIcon } from "./CustomIcon";
import CustomNoRowsOverlay from "../Resident/CustomNoRowsOverlay";

export default function DailyTasks() {
    const navigate = useNavigate();
    const { date } = useParams();

    const COLLECTION_COLOR = '#118ab2';
    const DELIVERY_COLOR = blue[900];

    const [selectedTab, setSelectedTab] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleTabClick = (tab) => {
        if (selectedTab.includes(tab)) {
            setSelectedTab(selectedTab.filter(t => t !== tab));
        } else {
            setSelectedTab([...selectedTab, tab]);
        }
    };

    const isCollect = (event) => {
        return event.plannedDeliveryDate == null
    }

    const fetchData = async (date) => {
        setLoading(true);
        try {
            const day = parseInt(date.substr(0, 2), 10);
            const month = parseInt(date.substr(2, 2), 10);
            const year = parseInt(date.substr(4, 4), 10);
            const response = await request('GET', `CustomerCollectRequest/GetAllByDate/${year}/${month}/${day}`);
            setEvents(response.data);
        } catch (error) {
            toast.error('Une erreur est survenue lors de la récupération des collectes');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (date) {
            fetchData(date);
        }
    }, [date]);

    const refresh = () => {
        fetchData(date);
    }

    const openGoogleMapsApp = (event) => {
        window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.customerAddress)}`;
    };
    const openWazeApp = (event) => {
        window.location.href = `https://waze.com/ul?q=${encodeURIComponent(event.customerAddress)}`;
    };


    const filteredEvents = () => {
        return events.filter((e) => {
            if (selectedTab.length === 0 || selectedTab.length === 2) {
                return e
            }
            if (selectedTab.includes('Livraisons')) {
                return !isCollect(e) ? e : 0
            }
            if (selectedTab.includes('Collectes')) {
                return isCollect(e) ? e : 0
            }
            return 0;
        })
    }


    return (
        <Grid container style={{ backgroundColor: "#0A1F41", fontFamily: "Poppins" }}>
            <Grid item xs={12} sx={{ color: "white", height: '15vh' }}>
                <Grid container spacing={3} pl={3} pt={4} pb={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item xs={12}>
                            <Grid item>
                                <Button onClick={() => navigate("/calendar")} sx={{ color: "white" }}>
                                    <ArrowBackIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" fontFamily={"Poppins"}>
                                    {"Quoi de prévu ?"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} mb={1}>
                            <Grid item>
                                <Button
                                    startIcon={<LocalShippingIcon />}
                                    variant={selectedTab.includes('Livraisons') ? 'contained' : 'outlined'}
                                    onClick={() => handleTabClick("Livraisons")}>
                                    {'Livraisons'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    startIcon={<SaveAltIcon />}
                                    variant={selectedTab.includes('Collectes') ? 'contained' : 'outlined'}
                                    onClick={() => handleTabClick("Collectes")}>
                                    {'Collectes'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button sx={{ color: "white" }} startIcon={<SyncIcon onClick={refresh} />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: "#E9E9E9", borderTopLeftRadius: "40px", borderTopRightRadius: "40px", height: '85vh' }}>
                {loading ? (
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    events.length > 0 ?
                        <>
                            <Grid container>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant='h3' m={2}>
                                        {selectedTab.length === 0 || selectedTab.length === 2 ? 'Tous' : selectedTab[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', fontWeight: 'bold', fontSize: 12 }}>
                                    <Typography variant='body' m={1}>
                                        {`${filteredEvents().length} chose${filteredEvents().length > 1 ? 's' : ''} à faire`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {

                                    filteredEvents().map((event) => (
                                        <Button sx={{ width: '100%', display: 'flex' }}
                                            onClick={() => {
                                                navigate(`/validate/${event.id}`)
                                            }}>
                                            <Grid
                                                p={0.5}
                                                container
                                                key={event.id}
                                                borderRadius={3}
                                                justifyContent={'space-between'}
                                                sx={{
                                                    textAlign: 'start',
                                                    background: 'white',
                                                    color: 'black',
                                                    width: '100%',
                                                    margin: 'auto',
                                                    borderLeft: `10px solid ${isCollect(event) ? COLLECTION_COLOR : DELIVERY_COLOR}`
                                                }}>
                                                <Grid item xs={2} >
                                                    {
                                                        isCollect(event) ?
                                                            <Avatar sx={{ backgroundColor: COLLECTION_COLOR }}>
                                                                <SaveAltIcon sx={{ color: 'white' }} />
                                                            </Avatar>
                                                            :
                                                            <Avatar sx={{ backgroundColor: DELIVERY_COLOR }}>
                                                                <LocalShippingIcon sx={{ color: 'white' }} />
                                                            </Avatar>
                                                    }
                                                </Grid>
                                                <Grid item xs={6}  >
                                                    <Typography variant="body1" sx={{ fontWeight: '1000', fontSize: 13 }}>
                                                        {event.customerCompanyName ? event.customerCompanyName : `${event.customerLastName} ${event.customerFirstName}`}

                                                        {event.effectiveCollectionDate || event.effectiveDeliveryDate ? ' ✅' : ''}

                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#c9c9c9', fontSize: 12 }}>
                                                        {event.customerAddress}
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation(); 
                                                        openGoogleMapsApp(event);
                                                    }} sx={{ backgroundColor: 'lightgray' }}>
                                                        <CustomIcon src='/maps_icon.svg' />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={(e) => {
                                                        e.stopPropagation();
                                                        openWazeApp(event);
                                                    }} sx={{ backgroundColor: blue[400] }}>
                                                        <CustomIcon src='/waze_icon.svg' />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    ))
                                }
                            </Grid>
                        </>
                        :
                        <CustomNoRowsOverlay text={'Aucune collecte/livraison aujourd\'hui !'} />
                )}
            </Grid>
        </Grid >
    );
}
