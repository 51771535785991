import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import QRCode from "react-qr-code";
import {
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Box,
    Container,
    TablePagination
} from '@mui/material';
import { format } from 'date-fns';
import fileDownload from 'js-file-download'
import { useSelector } from 'react-redux';
import frLocale from 'date-fns/locale/fr';
import { CheckBox } from '@mui/icons-material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Label from '../../components/label';
import { fetchInvoiceByGUID } from '../../services/invoiceService';
import { fetchBankAccountsByCompany } from '../../services/BankAccountService';
import Page404 from '../Page404';


export default function InvoiceDetailPage() {

    const { id } = useParams();
    const [invoice, setInvoice] = useState();
    const [error, setError] = useState(null);
    const connectedUser = useSelector((state) => state.auth.user);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const dataInvoice = await fetchInvoiceByGUID(id);
                const dataBankAccounts = await fetchBankAccountsByCompany(connectedUser.idCustomer);
                setInvoice(dataInvoice);
                setBankAccounts(dataBankAccounts);
                setIsLoading(false);
                setError(null);
            } catch (error) {
                setIsLoading(false);
                setError('Error fetching invoice details. Please try again later.');
            }
        }
        fetchAllData();
    }, [id]);

    const priceToString = (price) => {
        return price.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })
    }

    const generatePaymentQRCode = () => {
        if (!bankAccounts || bankAccounts.length === 0) {
            return '';
        }

        const bankAccount = bankAccounts[0];
        return `BCD
002
1
SCT
${bankAccount.bic}
BeeClean Web
${bankAccount.iban}
EUR${invoice.toPay}

${invoice.paymentCommunication}
`
    }

    const handleDownload = (url, filename) => {
        axios({
            url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const totalCleaningLine = invoice && invoice.details && invoice.details.reduce((acc, row) => acc + row.totalPriceTaxExcl, 0);

    const totalPerRate = invoice && invoice.invoiceLines && invoice.invoiceLines.reduce((acc, row) => {
        if (!acc[row.vatRate]) {
            acc[row.vatRate] = 0;
        }

        acc[row.vatRate] += row.totalPriceTax;
        return acc;
    }, {})

    return (
        <>

            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                <Button style={{ display: 'flex', alignItems: 'center' }} component={Link} to="/invoices">
                    <NavigateBeforeIcon /> {t('invoice-list-invoices')}
                </Button>
                {invoice && <Label color={'primary'}>{`${invoice.year}-${zeroPad(invoice.no, 3)}`}</Label>}

            </Breadcrumbs>
            <Card>
                <Grid container justifyContent={'space-between'}>

                    {!isLoading ? (
                        <>
                            <>
                                <Grid item sx={6}>
                                    <Typography variant='h4' sx={{ ml: 1, p: 1 }}>{invoice ? `${t('invoice-page-invoice')} ${invoice.year}-${zeroPad(invoice.no, 3)}` : t('invoice-page-invoice')}</Typography>
                                </Grid>
                                {
                                    invoice && invoice.idBlob > 0 && invoice.blob &&
                                    <>
                                        <Grid item sx={6}>
                                            <Button sx={{ p: 2 }}>
                                                <Link to={invoice.blob.path + invoice.blob.name} target="_blank" download>{t('invoice-page-download')}</Link>
                                            </Button>
                                        </Grid>
                                    </>
                                }

                            </>



                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">{t('invoice-page-description')}</TableCell>
                                            <TableCell align="left">{t('invoice-page-quantity')}</TableCell>
                                            <TableCell align="right">{t('invoice-page-amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            totalCleaningLine && totalCleaningLine > 0 ? (

                                                <TableRow
                                                    key={1}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 }, // Remove borders for last row
                                                    }}>
                                                    <TableCell align="left">{t('invoice-page-line-description')}</TableCell>
                                                    <TableCell align="left" />
                                                    <TableCell align="right">{totalCleaningLine ? priceToString(totalCleaningLine) : ""}</TableCell>
                                                </TableRow>)
                                                : ''

                                        }
                                        {invoice && invoice.invoiceLines && invoice.invoiceLines.map((row) => (
                                            row.residentLine === false &&
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 }, // Remove borders for last row
                                                }}>
                                                <TableCell align="left">{row.description}</TableCell>
                                                <TableCell align="left">{row.quantity}</TableCell>
                                                {row.moneyDiscount > 0 ?
                                                    <TableCell align="right">
                                                        <Box sx={{ textDecoration: "line-through" }}>
                                                            {priceToString(row.totalPriceTaxExcl + row.moneyDiscount)}</Box>
                                                        <Box>{priceToString(row.totalPriceTaxExcl)}</Box>
                                                    </TableCell>
                                                    :
                                                    <TableCell align="right">{priceToString(row.totalPriceTaxExcl)}</TableCell>
                                                }

                                            </TableRow>
                                        ))}

                                        <>
                                            {invoice && invoice.totalDiscount > 0 && (
                                                <>
                                                    <TableRow sx={{ borderTop: 2, borderColor: 'lightgrey' }}>
                                                        <TableCell colSpan={1} sx={{ border: 0 }} />
                                                        <TableCell><Typography>{t('invoice-page-sub-total')}</Typography></TableCell>
                                                        <TableCell align="right">{priceToString(invoice.totalPriceTaxExcl + invoice.totalDiscount)}</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell colSpan={1} sx={{ border: 0 }} />
                                                        <TableCell><Typography>{t('invoice-page-discount')}</Typography></TableCell>
                                                        <TableCell align="right">-{priceToString(invoice.totalDiscount)}</TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                            <TableRow sx={{ borderTop: 2, borderColor: 'lightgrey' }}>
                                                <TableCell colSpan={1} sx={{ border: 0 }} />
                                                <TableCell><Typography>{t('invoice-page-total-tax-excl')}</Typography></TableCell>
                                                <TableCell align="right">{invoice ? priceToString(invoice.totalPriceTaxExcl) : ''}</TableCell>
                                            </TableRow>
                                            {
                                                totalPerRate && Object.keys(totalPerRate).map(rate => (
                                                    <TableRow >
                                                        <TableCell colSpan={1} sx={{ border: 0 }} />
                                                        <TableCell><Typography>{t('invoice-page-total-tax')} {rate} %</Typography></TableCell>
                                                        <TableCell align="right">{invoice ? priceToString(totalPerRate[rate]) : ''}</TableCell>
                                                    </TableRow>
                                                ))
                                            }

                                            <TableRow >
                                                <TableCell colSpan={1} sx={{ border: 0, fontWeight: 'bold' }} />
                                                <TableCell><Typography sx={{ fontWeight: 'bold' }}>{t('invoice-page-total-tax-incl')}</Typography></TableCell>
                                                <TableCell align="right"><Typography sx={{ fontWeight: 'bold' }}>{invoice ? priceToString(invoice.totalPriceTaxIncl) : ''}</Typography></TableCell>
                                            </TableRow>
                                        </>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container mt={2} item xs={12} spacing={1} >
                                {
                                    invoice && invoice.dueDate &&
                                    <Grid item xs={2} sx={{ ml: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}> {t('invoice-page-due-date')} </Typography> {format(new Date(invoice.dueDate), "dd/MM/yyyy", { locale: frLocale })}
                                    </Grid>
                                }
                                {
                                    invoice && invoice.paymentCommunication &&
                                    <Grid item xs={2}>
                                        <Typography sx={{ fontWeight: 'bold' }}> {t('invoice-page-communication')} </Typography> {invoice.paymentCommunication}
                                    </Grid>
                                }
                                {
                                    bankAccounts && bankAccounts.length > 0 &&
                                    <Grid item xs={2} sx={{ pb: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>{bankAccounts.length > 1 ? t('invoice-page-bank-accounts') : t('invoice-page-bank-account')}</Typography>
                                        {
                                            bankAccounts.map((row) => (
                                                `${row.name} : ${row.iban}`
                                            ))
                                        }
                                    </Grid>
                                }
                                {
                                    invoice && invoice.status > 3 &&
                                    <Grid item xs={2} sx={{ pb: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>{t('invoice-page-pay-with-mobile')}</Typography>
                                        {
                                            <QRCode
                                                size={100}
                                                value={generatePaymentQRCode()}
                                                viewBox={`0 0 100 100`}
                                            />
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </>)
                        :
                        (
                            <>
                                <Grid container display="flex"
                                    justifyContent="center">
                                    <Grid item sx={{ pl: 2, pt: 2, mt: 1, mb: 1 }}>
                                        <h4><CircularProgress /></h4>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                </Grid></Card>
        </>
    );
}
