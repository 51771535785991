import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Typography, Paper, Box, CssBaseline, Scrollbar, IconButton, CardActionArea } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationImportantSharp from '@mui/icons-material/NotificationImportantSharp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchInvoiceList } from '../../services/invoiceService';

export default function LateInvoices() {

    const user = useSelector((state) => state.auth.user);
    const [invoiceList, setinvoiceList] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchInvoiceList(user.idCustomer);
            setinvoiceList(data);
        };
        fetchData();
    }, [user]);

    const Item = ({ nbr, borderColor, text, icon, status }) => {

        const navigate = useNavigate()

        return (
            <Card>
                <Paper key={1} elevation={10} sx={{ marginBottom: '8px', padding: '8px', borderLeft: `10px solid ${borderColor}}` }}>
                    <CardActionArea onClick={() => navigate('/invoices', { params: { status } })}>

                        <Grid
                            p={1}
                            container
                            spacing={3}
                            height='90px'
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item>
                                <Typography variant="h3">{icon} {nbr} {nbr > 1 ? t('invoice-to-pay-invoices') : t('late-invoices-invoice')}</Typography>
                            </Grid>
                            <Grid item>
                                <div style={{ borderBottom: `5px solid ${borderColor}` }}>
                                    <Typography variant="h5">
                                        {text}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardActionArea>
                </Paper >
            </Card >
        )
    }
    return (
        <>
            <Typography mb={1}>📄 {t('late-invoices-billing-status')} </Typography>
            <Box>
                {
                    invoiceList.filter((i) => i.status === 2).length > 0 &&
                    <Item
                        nbr={invoiceList.filter((i) => i.status === 2).length}
                        text={t('late-invoices-to-pay-status')}
                        status={8}
                        borderColor="#349ECC"
                        icon={<NotificationImportantSharp />}
                    />
                }
                {
                    invoiceList.filter((i) => i.status === 3).length > 0 &&
                    <Item
                        nbr={invoiceList.filter((i) => i.status === 3).length}
                        text={t('late-invoices-ok-status')}
                        status={3}
                        borderColor="#8ecd7a"
                        icon={<NotificationsNoneIcon />}
                    />
                }
                {
                    invoiceList.filter((i) => i.status === 4).length > 0 &&
                    <Item
                        nbr={invoiceList.filter((i) => i.status === 4).length}
                        text={t('late-invoices-first-reminder-status')}
                        status={4}
                        borderColor="#ffd969"
                        icon={<NotificationsNoneIcon />}
                    />
                }
                {
                    invoiceList.filter((i) => i.status === 5).length > 0 &&
                    <Item
                        nbr={invoiceList.filter((i) => i.status === 5).length}
                        text={t('late-invoices-second-reminder-status')}
                        status={5}
                        borderColor="#ffb145"
                        icon={<NotificationsActiveIcon />}
                    />
                }
                {
                    invoiceList.filter((i) => i.status === 8).length > 0 &&
                    <Item
                        nbr={invoiceList.filter((i) => i.status === 8).length}
                        text={t('late-invoices-third-reminder-status')}
                        status={8}
                        borderColor="#EF767A"
                        icon={<ReportProblemIcon />}
                    />
                }
            </Box>
        </>
    );
}